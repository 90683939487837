import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import * as Sentry from "@sentry/react";

const initialState = {};

export const userBalanceUpdate = createAsyncThunk(
  "updateWallet/userBalanceUpdate",
  async () => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.userBalance}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }

    return response.data;
  }
);

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userBalanceUpdate.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(userBalanceUpdate.fulfilled, (state, action) => {})
      .addCase(userBalanceUpdate.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

// export const {} = walletSlice.actions;
export const walletReducer = walletSlice.reducer;
