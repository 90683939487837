import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const GetMoreCard = ({
  title,
  text,
  btnText,
  icon,
  openNewTab,
  route,
  assetPath,
}) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    if (openNewTab) {
      window.open(route, "_blank");
    } else {
      navigate(route);
    }
  };
  return (
    <div className="col-lg-6 mb-4">
      <div className="aiml-card getMore-card">
        <div className="aiml-card-body">
          <h4 className="card-title">
            <img src={`${assetPath}${icon}`} alt="icon" /> {title}
          </h4>
          <p className="card-text">{text}</p>
        </div>
        <div className="btn-wrapper">
          <Button className="aiml-btn dash-btn" onClick={handleCardClick}>
            {btnText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GetMoreCard;
