import React, { useContext } from "react";
import { Modifications } from "./Modifications";
import { ModelContext } from "../ContextApis/ModelContext";
import { TextModification } from "./TextModification";

export const ModelFilters = () => {
  const { selectedMenu } = useContext(ModelContext);

  // console.log(selectedMenu,"in model filters component")

  let renderComponent;

  if (
    selectedMenu === "chat" ||
    selectedMenu === "language" ||
    selectedMenu === "code"
  ) {
    renderComponent = <TextModification />;
  } else if (selectedMenu === "transcribe") {
    // renderComponent = <SpeechModification />
  } else if (selectedMenu === "knowledge") {
  } else {
    renderComponent = <Modifications />;
  }
  return <>{renderComponent}</>;
};
