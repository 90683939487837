import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import * as Sentry from "@sentry/react";

const initialState = {
  trackUserLogs: {
    isTrackLoading: false,
  },
  speechMode: {
    isSpeechLoading: false,
  },
  completeMeter: {
    isCompleteMeterLoading: false,
    completeMeterData: null,
  },
  totalBill: {
    isTotalBillLoading: false,
    totalBilldata: [],
  },
};

export const trackUserSpeechLogs = createAsyncThunk(
  "trackUserLogs/trackUserSpeechLogs",
  async ({ user_id, from_date, to_date, path }) => {
    const response = await axiosInstance
      .get(
        `${MINSKY_URL.TrackUserLogs}?user_id=${user_id}&from_date=${from_date}&to_date=${to_date}&path=${path}`
      )
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const speechModeOptions = createAsyncThunk(
  "speechMode/speechModeOptions",
  async ({ user_id }) => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.GetModelOptions}?user_id=${user_id}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const completeMeterEvents = createAsyncThunk(
  "completeMeter/completeMeterEvents",
  async ({ model, toDate, fromDate }) => {
    const response = await axiosInstance
      .get(
        `${MINSKY_URL.completeMeter}?from_date=${fromDate}&to_date=${toDate}&model=${model}`
      )

      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const downloadBill = createAsyncThunk(
  "downloadBill/downloadBill",
  async ({ month }) => {
    try {
      const response = await axiosInstance.get(
        `${MINSKY_URL.downloadBill}?date=${month}`
      );
      if (response.status === 200) {
        return response.data; // Return the PDF content
      } else {
        throw new Error("Failed to download bill: Invalid status code");
      }
    } catch (error) {
      // Log error to Sentry
      Sentry.captureException(error);
      throw error; // Rethrow the error for handling in the component
    }
  }
);

export const handleBlobDownload = (blob) => {
  const url = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = "bill.pdf";

  document.body.appendChild(downloadLink);

  downloadLink.click();

  // Clean up: remove the link and revoke the URL
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(trackUserSpeechLogs.pending, (state, action) => {
        state.status = "pending";
        state.trackUserLogs.isTrackLoading = true;
      })
      .addCase(trackUserSpeechLogs.fulfilled, (state, action) => {
        state.trackUserLogs.isTrackLoading = false;
      })
      .addCase(trackUserSpeechLogs.rejected, (state, action) => {
        state.status = "failed";
        state.trackUserLogs.isTrackLoading = false;
      })
      //------------------------------------------------------------------
      .addCase(speechModeOptions.pending, (state, action) => {
        state.status = "pending";
        state.speechMode.isSpeechLoading = true;
      })
      .addCase(speechModeOptions.fulfilled, (state, action) => {
        state.speechMode.isSpeechLoading = false;
      })
      .addCase(speechModeOptions.rejected, (state, action) => {
        state.status = "failed";
        state.speechMode.isSpeechLoading = false;
      })
      //------------------------------------------------------------------
      .addCase(completeMeterEvents.pending, (state, action) => {
        state.status = "pending";
        state.completeMeter.isCompleteMeterLoading = true;
      })
      .addCase(completeMeterEvents.fulfilled, (state, action) => {
        state.completeMeter.isCompleteMeterLoading = false;
        state.completeMeter.completeMeterData = action.payload;
      })
      .addCase(completeMeterEvents.rejected, (state, action) => {
        state.status = "failed";
        state.completeMeter.isCompleteMeterLoading = false;
      })

      //------------------------------------------------------------------
      .addCase(downloadBill.pending, (state, action) => {
        state.status = "pending";
        state.totalBill.isTotalBillLoading = true;
      })
      .addCase(downloadBill.fulfilled, (state, action) => {
        state.totalBill.isTotalBillLoading = false;
        state.totalBill.completeMeterData = action.payload;
      })
      .addCase(downloadBill.rejected, (state, action) => {
        state.status = "failed";
        state.totalBill.isTotalBillLoading = false;
      });
  },
});

// export const {} = analyticsSlice.actions;
export const analyticsReducer = analyticsSlice.reducer;
