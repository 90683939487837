import React from "react";
import { Route, Routes } from "react-router-dom";
import YourOrganisation from "../Components/Settings/YourOrganisation";
import ApiKeys from "../Components/Settings/ApiKeys";
import { MembersInvites } from "../Components/Settings/MembersInvites";
import Logs from "../Components/Settings/Logs";
import PlansBilling from "../Components/Settings/PlansBilling";

const SettingsRouts = () => {
  // const { isAuthenticated } = useContext(LoginContext);
  return (
    <div>
      {/* {isAuthenticated ? ( */}
      <Routes>
        <Route path="/yourOrganisation" element={<YourOrganisation />}></Route>
        <Route path="/membersInvites" element={<MembersInvites />}></Route>
        <Route path="/apiKeys" element={<ApiKeys />}></Route>
        <Route path="/logs" element={<Logs />}></Route>
        <Route path="/plansBilling" element={<PlansBilling />}></Route>
      </Routes>
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};

export default SettingsRouts;
