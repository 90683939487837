import React from "react";
import { Modal } from "antd";
import Share from "../Assets/share.svg";
import Expand_Black from "../Assets/expand_black.svg";
import Suitcase from "../Assets/suitcase.svg";

const WelcomePlaygroundModal = ({ openWelcomeModal, onCancelModal }) => {
  return (
    <>
      <Modal
        className="welcome-modal"
        wrapClassName="aiml-modal-dialog"
        title={null}
        width={730}
        open={openWelcomeModal}
        okText="Get Started"
        onOk={onCancelModal}
      >
        <div className="welcome-header">
          <h3 className="modal-title">Welcome to Playground</h3>
        </div>
        <div className="welcome-body">
          <p className="w-text">
            Enter an instruction or select a preset, and watch the API respond
            with a message that attempts to match or answer the query.
          </p>
          <p className="w-text">
            You can control which <span>model</span> completes your request by
            changing the model.
          </p>
          <h4 className="body-subtitle">Keep In Mind</h4>
          <div className="text-icon-wrap">
            <img src={Share} alt="icon" />
            <p className="w-text">
              Use good judgment when sharing outputs, and attribute them to your
              name or company. <span>Learn more</span>.
            </p>
          </div>
          <div className="text-icon-wrap">
            <img src={Expand_Black} alt="icon" />
            <p className="w-text">
              Requests submitted to our API and Playground will not be used to
              train or improve future models. <span>Learn more</span>.
            </p>
          </div>
          <div className="text-icon-wrap">
            <img src={Suitcase} alt="icon" />
            <p className="w-text">
              Each models' training data cuts off at a different time. Our
              newest models have knowledge of many current events up to April
              2023. <span>Learn more</span>.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WelcomePlaygroundModal;
