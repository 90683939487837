import React, { useState, useContext, useEffect, useRef } from "react";
import { PlaygroundRouters } from "../Navigation/PlaygroundRouters";
import { Outlet, useNavigate } from "react-router-dom";
import { ModelContext } from "../ContextApis/ModelContext";
import { useLocation } from "react-router-dom";
import { LoginContext } from "../ContextApis/LoginContext";
import Language from "../Assets/language.svg";
import { Button, Layout, Menu, message } from "antd";
import { ModelFilters } from "../Model/ModelFilters";
import "./Layout.css";
import header_data from "../Data/header_description.json";
import intercom from "../Utilities/intercom";
import {
  textToTextModelNames,
  textToSpeechLangNames,
  textToSpeechModelNames,
  speechToTextModelNames,
  clearPreviousReducer,
  getPromptConversationDataAction,
} from "../slices/modelSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { CodeModal } from "../Components/viewCodeModal";
// import CheckableTag from "antd/es/tag/CheckableTag";
import WelcomePlaygroundModal from "../Components/WelcomePlaygroundModal";
import KeycloakService from "../Utilities/KeycloakService";
import { useSelector } from "react-redux";
const { Header, Content } = Layout;

const assetPath = "https://neurobridge-public.objectstore.e2enetworks.net";
const Logo = assetPath + "/aiml/logo.svg";
const Logout = assetPath + "/aiml/logout.svg";
const Question = assetPath + "/aiml/question.svg";
const Code = assetPath + "/aiml/code.svg";
// const Dots = assetPath + "/aiml/dots.svg";
const chatIcon = assetPath + "/aiml/chat.svg";
const CodeB = assetPath + "/aiml/codeB.svg";
const User_Small = assetPath + "/aiml/user_small.svg";
const Organisation_Outline = assetPath + "/aiml/organisation_outline.svg";
const Terms_Outline = assetPath + "/aiml/terms_outline.svg";
const User_plus_Outline = assetPath + "/aiml/user_outline_plus.svg";
const Card_Outline = assetPath + "/aiml/card_outline.svg";
const Key_Outline = assetPath + "/aiml/key_outline.svg";
const File_Outline = assetPath + "/aiml/file_outline.svg";
const Privacy_Outline = assetPath + "/aiml/privacy_outline.svg";
const Left_d_Arrow = assetPath + "/aiml/left_d_arrow.svg";

export const LayoutPlayground = () => {
  const [userDetail, setUserDetail] = useState(null);
  const orgDataCheck = useSelector(
    (state) => state.setting.orgCheckData.orgDataCheck
  );

  // const { "*": modelId } = useParams();
  // const idIndex = modelId?.lastIndexOf("/") + 1;
  // const extractedId = modelId?.substring(idIndex);

  const { keycloak } = KeycloakService;

  const {
    setUserId,
    setIsAuthenticated,
    setFirstName,
    setLastName,
    setProfileEmail,
    setProfilePhone,
    setAuthData,
    handleLogoutUser,
    tokenParsedData,
    // AuthData,
  } = useContext(LoginContext);

  const dispatch = useDispatch();
  const location = useLocation();
  const [modelName, setmodelName] = useState();

  const keyCloakToken = Cookies.get("keyCloakToken");
  const { cardName } = location.state || {};

  // console.log(modelName,"modele naemes")
  const [selectedModelName, setselectedModelName] = useState("");
  const [previousModelName, setPreviousModelName] = useState("");

  const [selectedMenu, setselectedMenu] = useState("chat");
  const [previousMenu, setPreviousMenu] = useState("chat");
  const [helpClicked, setHelpClicked] = useState(false);

  const [selectedLanguage, setselectedLanguage] = useState("English");
  const [maxSeq, setMaxSeq] = useState(null);
  const [outputLengthRange, setOutputLengthRange] = useState([512, maxSeq]);
  const [temperatureRange, setTemperatureRange] = useState([0.7, 2]);
  const [topPRange, setTopPRange] = useState([0.7, 2]);
  const [topKRange, setTopKRange] = useState([50, 100]);
  const [repetitionPenaltyRange, setRepetitionPenaltyRange] = useState([
    1.2, 2,
  ]);
  const [selectedVoice, setSelectedVoice] = useState("male");
  const [allanguages, setAllanguages] = useState("");
  const [speechToTextModel, setspeechToTextModel] = useState();
  const [speechToTextSelectedModel, setspeechToTextSelectedModel] =
    useState("ASR_HQ");
  const [textToSpeechmodel, settextToSpeechmodel] = useState();
  const [textToSpeechmodelSelected, setTextToSpeechmodelSelected] =
    useState("VOICE_HQ");
  const [systemPrompt, setSystemPrompt] = useState(
    "You are an assistant and your task is to help with user queries"
  );
  const [sysInstructionPrompt, setSysInstructionsPrompt] = useState();
  const [isActive, setIsActive] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleModalSetting, setToggleModalSetting] = useState(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

  const [messages, setMessages] = useState([]);
  const [conversationHistoryId, setConversationHistoryId] = useState("");

  const eventSourceRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const retrive = async () => {
      try {
        const info = await keycloak.loadUserProfile();
        setUserDetail(info);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    retrive();
  }, [keycloak]);

  const handleDashboardNavigation = () => {
    navigate("/dashboard");
  };
  const handleSettingNavigation = () => {
    navigate("/setting/yourOrganisation");
  };
  const handleLogoutDrop = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (orgDataCheck?.user_detail?.is_new_user === true) {
      setIsWelcomeModalOpen(true);
    }
  }, [orgDataCheck]);

  const handleCloseWelcomeModal = () => {
    setIsWelcomeModalOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    // Cookies.remove("keyCloakToken", keyCloakToken);
    setIsAuthenticated(false);
    setAuthData(false);
    setFirstName(false);
    setLastName(false);
    setProfileEmail(false);
    setProfilePhone(false);
    setUserId(false);
    handleLogoutUser();
    const cookies = Cookies.get();

    // Iterate over all cookies and remove each one
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
    // window.location.reload();
    navigate("/");
  };

  const handleMenuClick = ({ key }) => {
    setPreviousMenu(selectedMenu);
    setselectedMenu(key);
    navigate(key);
    dispatch(clearPreviousReducer());
    setConversationHistoryId("");
    const payload = {
      limit: 5,
    };
    dispatch(getPromptConversationDataAction(payload));
  };

  const handleMenuClickDropdown = (key) => {
    if (key === "privacy" || key === "tos") {
      navigate(`/${key}`);
    } else {
      navigate(`/setting/${key}`);
    }
  };
  const handleCopyCodeModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseCopyCodeModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseDropdown = () => {
    setIsActive(false);
  };
  useEffect(() => {
    const pathSegments = location?.pathname?.split("/");
    // console.log(pathSegments, "path segments");
    const key = pathSegments[2];
    setselectedMenu(key || "chat");
  }, [location]);

  const navigateAPIDoc = (tab) => {
    if (tab === "docs") {
      window.open("http://api.ai.ml/docs/swagger/", "_blank");
    } else {
      window.open("https://community.ai.ml/", "_blank");
    }
  };

  let textToTextheaderComponent;

  if (selectedModelName === "MISTRAL_7B") {
    textToTextheaderComponent = header_data.MISTRAL_7B;
  } else if (selectedModelName === "MISTRAL_8X7B") {
    textToTextheaderComponent = header_data.MISTRAL_8X7B;
  } else if (selectedModelName === "LLAMA_7B") {
    textToTextheaderComponent = header_data.LLAMA_7B;
  } else if (selectedModelName === "LLAMA_70B") {
    textToTextheaderComponent = header_data.LLAMA_70B;
  } else if (selectedModelName === "CODELLAMA_70B_INST") {
    textToTextheaderComponent = header_data.CODELLAMA_70B_INST;
  } else if (selectedModelName === "DEEPSEEK_CODER_33B") {
    textToTextheaderComponent = header_data.DEEPSEEK_CODER_33B;
  }

  // let speechToTextheaderComponent;

  // if (selectedModelName === 'ASR_HQ') {
  //     speechToTextheaderComponent = header_data.MISTRAL_7B
  // } else if (selectedModelName === 'ASR_FS') {
  //     speechToTextheaderComponent = header_data.MISTRAL_8X7B
  // }

  // let speechToTextheaderComponent;

  // if (textToSpeechmodelSelected === 'VOICE_HQ') {
  //     speechToTextheaderComponent = header_data.VOICE_HQ
  // } else if (textToSpeechmodelSelected === 'VOICE_FS') {
  //     speechToTextheaderComponent = header_data.VOICE_FS
  // }
  // console.log(textToSpeechmodelSelected)

  let textToSpeechHeaderComponent;
  if (textToSpeechmodelSelected === "VOICE_HQ") {
    textToSpeechHeaderComponent = header_data.VOICE_HQ;
  } else if (textToSpeechmodelSelected === "VOICE_FS") {
    textToSpeechHeaderComponent = header_data.VOICE_FS;
  }

  // console.log(extractedId)

  const getModelData = () => {
    dispatch(
      textToTextModelNames({
        model_id: selectedMenu,
      })
    )
      .then((res) => {
        const modelnames = res?.payload?.data;
        setmodelName(modelnames || []);
      })
      .catch((error) => {
        if (error.payload && error.payload.detail) {
          message.error(error.payload.detail);
        } else {
          message.error("Something went wrong! Contact Minsky Support Team");
        }
      });
  };

  useEffect(() => {
    if (keyCloakToken) {
      getModelData();
    }
  }, [dispatch, selectedMenu, keyCloakToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (modelName && modelName.length > 0 && !cardName) {
      // Set the selectedModelName state to an object containing both model_name and model_id
      setselectedModelName({
        model_name: modelName[0]?.model_name || "Select Model",
        model_id: modelName[0]?.model_id || "Select Model ID",
        max_seq: modelName[0]?.max_seq || 128,
        start_prompt: modelName[0]?.start_prompt
          ? modelName[0]?.start_prompt
          : null,
        end_prompt: modelName[0].end_prompt ? modelName[0].end_prompt : null,
        stop_seq: modelName[0].stop_seq ? modelName[0].stop_seq : null,
      });
      setPreviousModelName({
        model_name: modelName[0]?.model_name || "Select Model",
        model_id: modelName[0]?.model_id || "Select Model ID",
        max_seq: modelName[0]?.max_seq || 128,
        start_prompt: modelName[0]?.start_prompt
          ? modelName[0]?.start_prompt
          : null,
        end_prompt: modelName[0].end_prompt ? modelName[0].end_prompt : null,
        stop_seq: modelName[0].stop_seq ? modelName[0].stop_seq : null,
      });
    }
  }, [modelName, cardName]);

  const getLanguages = () => {
    dispatch(textToSpeechLangNames())
      .then((res) => {
        const language = res.payload;
        setAllanguages(language || []);
      })
      .catch((error) => {
        if (error.payload && error.payload.detail) {
          message.error(error.payload.detail);
        } else {
          message.error("Something went wrong! Contact Minsky Support Team");
        }
      });
  };

  useEffect(() => {
    if (keyCloakToken) {
      getLanguages();
    }
  }, [keyCloakToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalSettingToggle = () => {
    setToggleModalSetting(!toggleModalSetting);
  };
  const getSpeechToTextModel = () => {
    dispatch(speechToTextModelNames())
      .then((res) => {
        const modelnames = res?.payload;
        setspeechToTextModel(modelnames || []);
      })
      .catch((error) => {
        if (error.payload && error.payload.detail) {
          message.error(error.payload.detail);
        } else {
          message.error("Something went wrong! Contact Minsky Support Team");
        }
      });
  };

  useEffect(() => {
    if (keyCloakToken) {
      getSpeechToTextModel();
    }
  }, [keyCloakToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTextToTextmodel = () => {
    dispatch(textToSpeechModelNames())
      .then((res) => {
        const modelnames = res?.payload;
        // console.log(modelnames)
        settextToSpeechmodel(modelnames || []);
      })
      .catch((error) => {
        if (error?.payload && error?.payload.detail) {
          message.error(error?.payload?.detail);
        } else {
          message.error("Something went wrong! Contact Minsky Support Team");
        }
      });
  };

  // const handleProfileBalanceClick = () => {
  //   navigate("/dashboard/billing");
  // };

  // const handleButtonClick = () => {
  //   intercom.show();
  // };

  useEffect(() => {
    if (helpClicked && userDetail) {
      intercom.boot({
        app_id: "hyitk9q2",
        user_id: userDetail.id,
        name: userDetail.username,
        email: userDetail.email,
        created_at: orgDataCheck?.user_detail?.created_at,
      });

      return () => {
        intercom.shutdown();
      };
    }
  }, [helpClicked, userDetail, orgDataCheck]);

  useEffect(() => {
    if (keyCloakToken) {
      getTextToTextmodel();
    }
  }, [keyCloakToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleHelpClick = () => {
    setHelpClicked(true);
    intercom.show();
  };

  return (
    <>
      <Layout>
        <Layout style={{ marginLeft: 200 }}>
          <div>
            <Header className="header-wrapper">
              <div className="header-logo">
                <img
                  src={Logo}
                  className="logo"
                  alt="logo"
                  onClick={() => navigate("/dashboard")}
                />
              </div>

              <div className="nav-links">
                <ul>
                  <li>
                    <div
                      onClick={handleDashboardNavigation}
                      className={`footer_sider_bar`}
                    >
                      Dashboard
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div className={`footer_sider_bar ${"active"}`}>
                      Playground
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div
                      className={`footer_sider_bar `}
                      onClick={handleSettingNavigation}
                    >
                      Settings
                    </div>
                  </li>
                </ul>
              </div>
              <div className="user-settings-main">
                {/* <div onClick={handleOpenWelcomeModal}>Modal</div> */}
                <div
                  onClick={() => navigateAPIDoc("community")}
                  className="docs-cum"
                >
                  Community
                </div>
                <div
                  onClick={() => navigateAPIDoc("docs")}
                  className="docs-cum"
                >
                  Docs
                </div>
                <div className="logout-wrap">
                  <img
                    src={Logout}
                    onClick={handleLogoutDrop}
                    className="logout-icon"
                    alt="logoutIcon"
                  />

                  {isActive ? (
                    <>
                      <div
                        className="drop-backDrop"
                        onClick={handleCloseDropdown}
                      >
                        {/* close */}
                      </div>
                      <div className="drop-list">
                        <div className="drop-header">
                          <img src={User_Small} alt="icon" />
                          <div>
                            <h4 className="drop-title">
                              {userDetail?.username}
                            </h4>
                            <p className="drop-text">{userDetail?.email}</p>
                          </div>
                        </div>
                        <div className="drop-body ">
                          <p
                            className="drop-item "
                            onClick={() =>
                              handleMenuClickDropdown("yourOrganisation")
                            }
                          >
                            <img src={Organisation_Outline} alt="icon" />
                            Your Organisation
                          </p>
                          <p
                            className="drop-item"
                            onClick={() =>
                              handleMenuClickDropdown("membersInvites")
                            }
                          >
                            <img src={User_plus_Outline} alt="icon" />
                            Members & Invites
                          </p>
                          <p
                            className="drop-item"
                            onClick={() =>
                              handleMenuClickDropdown("plansBilling")
                            }
                          >
                            <img src={Card_Outline} alt="icon" />
                            Plan & Pricing
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("apiKeys")}
                          >
                            <img src={Key_Outline} alt="icon" />
                            API Keys
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("logs")}
                          >
                            <img src={File_Outline} alt="icon" />
                            Logs
                          </p>
                          {/* <p className="drop-item">
                          <img src={Data_Outline} alt="icon" />
                          Usage
                        </p> */}
                        </div>
                        <div className="drop-footer">
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("privacy")}
                          >
                            <img src={Privacy_Outline} alt="icon" />
                            Privacy Policy
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("tos")}
                          >
                            <img src={Terms_Outline} alt="icon" />
                            Terms of Service
                          </p>
                          <p
                            className="drop-item logout"
                            onClick={handleLogout}
                          >
                            Logout
                          </p>
                        </div>
                        {/* <li
                     className="drop-item"
                     onClick={handleProfileBalanceClick}
                   >
                     Wallet
                   </li> */}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div
                className="profile-balance"
                onClick={handleProfileBalanceClick}
              >
                <Statistic
                  title={
                    <div className="balance-main">
                      <AccountBalanceWalletIcon
                        fontSize="large"
                        style={{ color: "#896ACB" }}
                      />
                      <span className="balance-fontsize">
                        {userServicebalance
                          ? ` ₹ ${userServicebalance}`
                          : `₹ ${0}`}
                      </span>
                    </div>
                  }
                  value={null}
                  valueRender={() => null}
                  precision={2}
                />
              </div> */}
            </Header>
          </div>
          <div className="sub-header-wrapper">
            <div className="menu-items-main">
              <Menu
                mode="inline"
                selectedKeys={[selectedMenu]}
                onClick={handleMenuClick}
                items={[
                  {
                    key: "chat",
                    icon: <img src={chatIcon} alt="chat-icon" />,
                    label: "Chat",
                  },
                  {
                    key: "language",
                    icon: <img src={Language} alt="chat-icon" />,
                    label: "Language",
                  },
                  {
                    key: "code",
                    icon: <img src={CodeB} alt="chat-icon" />,
                    label: "Code",
                  },
                ]}
              />
              <div className="btn-main">
                <button
                  type="button"
                  className="side-btn outlined"
                  onClick={handleHelpClick}
                >
                  <img src={Question} alt="question" />
                  Get Help
                </button>
                <button
                  type="button"
                  className="side-btn filled"
                  onClick={handleCopyCodeModal}
                >
                  <img src={Code} alt="code" />
                  View Code
                </button>
                {/* <div className="dropDown">
                  <button type="button" className="drop-btn">
                    <img src={Dots} alt="code" />
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="plaground-header-description">
            {(() => {
              switch (selectedMenu) {
                case "chat":
                  return textToTextheaderComponent;
                case "voice":
                  return textToSpeechHeaderComponent;
                default:
              }
            })()}
          </div>
          <div className="modal-container-Wrapper">
            <Button
              className={`setting-toggle-btn ${
                toggleModalSetting ? "" : "d-none"
              }`}
              onClick={handleModalSettingToggle}
            >
              <img src={Left_d_Arrow} alt="icon" />
            </Button>
            <div className="model-container">
              <Content
                className={
                  toggleModalSetting ? "content-col-12" : "content-col-8"
                }
              >
                <ModelContext.Provider
                  value={{
                    modelName,
                    selectedModelName,
                    selectedLanguage,
                    outputLengthRange,
                    temperatureRange,
                    topPRange,
                    topKRange,
                    repetitionPenaltyRange,
                    selectedVoice,
                    systemPrompt,
                    setSysInstructionsPrompt,
                    sysInstructionPrompt,
                    tokenParsedData,
                    cardName,
                    setMessages,
                    messages,
                    conversationHistoryId,
                    setConversationHistoryId,
                    selectedMenu,
                    previousMenu,
                    setPreviousMenu,
                    previousModelName,
                    setPreviousModelName,
                    eventSourceRef,
                  }}
                >
                  <PlaygroundRouters />
                </ModelContext.Provider>
              </Content>
              {selectedMenu === "knowledge" ||
              selectedMenu === "transcribe" ||
              selectedMenu === "knowledgestart" ? (
                ""
              ) : (
                <Content
                  className={`content-col-4 ${
                    toggleModalSetting ? "right-100" : "right-0"
                  }`}
                >
                  <ModelContext.Provider
                    value={{
                      modelName,
                      selectedModelName,
                      setselectedModelName,
                      selectedLanguage,
                      setselectedLanguage,
                      selectedMenu,
                      setOutputLengthRange,
                      outputLengthRange,
                      setTemperatureRange,
                      temperatureRange,
                      setTopPRange,
                      topPRange,
                      setTopKRange,
                      topKRange,
                      repetitionPenaltyRange,
                      setRepetitionPenaltyRange,
                      setMaxSeq,
                      maxSeq,
                      setSelectedVoice,
                      selectedVoice,
                      allanguages,
                      systemPrompt,
                      setSystemPrompt,
                      textToSpeechmodel,
                      setTextToSpeechmodelSelected,
                      textToSpeechmodelSelected,
                      speechToTextSelectedModel,
                      setspeechToTextSelectedModel,
                      speechToTextModel,
                      setspeechToTextModel,
                      setSysInstructionsPrompt,
                      sysInstructionPrompt,
                      tokenParsedData,
                      cardName,
                      toggleModalSetting,
                      setToggleModalSetting,
                      setMessages,
                      messages,
                      conversationHistoryId,
                      setConversationHistoryId,
                      // selectedMenu,
                      previousMenu,
                      setPreviousMenu,
                      previousModelName,
                      setPreviousModelName,
                      eventSourceRef,
                    }}
                  >
                    <ModelFilters />
                  </ModelContext.Provider>
                </Content>
              )}
            </div>
          </div>

          <CodeModal open={isModalOpen} onCancel={handleCloseCopyCodeModal} />
          <WelcomePlaygroundModal
            openWelcomeModal={isWelcomeModalOpen}
            onCancelModal={handleCloseWelcomeModal}
          />
        </Layout>
      </Layout>
      <Outlet />
    </>
  );
};
