import "./App.css";
import { useState, React, useEffect, useCallback } from "react";
import { LayoutDashboard } from "./Layouts/LayoutDashboard";
import { Route, Routes, useNavigate } from "react-router-dom";
import { LoginContext } from "./ContextApis/LoginContext";
import { LayoutPlayground } from "./Layouts/LayoutPlayground";
import { UpdatePassword } from "./Authentication/UpdatePassword";
import { useSelector, useDispatch } from "react-redux";
import { userBalanceUpdate } from "./slices/walletSlice";
import LayoutSetting from "./Layouts/LayoutSetting";
import KeycloakService from "./Utilities/KeycloakService";
import { keyCloakAction } from "./slices/auth";
import Cookies from "js-cookie";
import { orgCheckAction } from "./slices/settingsSlice";
import LayoutPrivacy from "./Layouts/LayoutPrivacy";
import LayoutTermsNCondition from "./Layouts/LayoutTermsNCondition";
import { ToastContainer } from "react-toastify";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { keycloak, clearAppSession } = KeycloakService;

  const { is_authenticated, data } = useSelector((state) => state.auth.login);

  const [authenticated, setAuthenticated] = useState(false);
  const [tokenParsedData, setTokenParsedData] = useState(null);
  const [AuthData, setAuthData] = useState(data?.api_key ? data.api_key : "");
  const [firstName, setFirstName] = useState(
    data?.first_name ? data.first_name : ""
  );
  const [lastName, setLastName] = useState(data?.lastName ? data.lastName : "");
  const [profileEmail, setProfileEmail] = useState(
    data?.email ? data.email : ""
  );
  const [profilePhone, setProfilePhone] = useState(
    data?.mobile_number ? data.mobile_number : ""
  );
  const [userId, setUserId] = useState(data?.user_id ? data.user_id : "");
  const [isAuthenticated, setIsAuthenticated] = useState(
    is_authenticated?.toString() ? is_authenticated.toString() : false
  );
  const [userServicebalance, setUserServicebalance] = useState(0);
  const updateUserBalance = useCallback(async () => {
    if (authenticated) {
      dispatch(userBalanceUpdate())
        .then((res) => {
          setUserServicebalance(res.payload.user_balance);
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    }
  }, [authenticated, dispatch, setUserServicebalance]);
  useEffect(() => {
    const storedAuthentication = is_authenticated.toString() === "true";
    setIsAuthenticated(storedAuthentication);
  }, [is_authenticated, setIsAuthenticated]);
  useEffect(() => {
    if (authenticated) {
      updateUserBalance();
      const apiCallInterval = setInterval(updateUserBalance, 300000);
      return () => clearInterval(apiCallInterval);
    }
  }, [authenticated, updateUserBalance]);

  useEffect(() => {
    const initKeycloak = async () => {
      try {
        const res = await keycloak.init({
          onLoad: "login-required",
        });
        if (keycloak && keycloak.token) {
          Cookies.set("keyCloakToken", keycloak.token);
          dispatch(orgCheckAction());

          navigate("/dashboard");
        }

        setAuthenticated(res ? true : false);
        setTokenParsedData(keycloak?.tokenParsed?.name);
        dispatch(keyCloakAction(keycloak.token));
      } catch (error) {
        console.error("Failed to initialize adapter:", error);
      }
    };

    initKeycloak();
  }, [keycloak, dispatch, navigate]);

  const handleLogoutUser = async () => {
    try {
      await keycloak.logout({ redirectUri: window.location.origin });
      await clearAppSession();
      console.log("User logged out and session cleared");
    } catch (err) {
      console.error("Failed to logout", err);
    }
  };

  if (!keycloak) {
    return <div>Loading...</div>;
  }
  return (
    <div className="backColor">
      <LoginContext.Provider
        value={{
          isAuthenticated,
          setIsAuthenticated,
          AuthData,
          setAuthData,
          userId,
          setUserId,
          firstName,
          profilePhone,
          lastName,
          profileEmail,
          setFirstName,
          setLastName,
          setProfileEmail,
          setProfilePhone,
          userServicebalance,
          handleLogoutUser,
          tokenParsedData,
        }}
      >
        <Routes>
          {authenticated ? (
            <>
              <Route
                path="/update/password/:key"
                element={<UpdatePassword />}
              />
              <Route path="/" element={<LayoutPlayground />} />
              <Route
                path="/playground/*"
                element={<LayoutPlayground />}
              ></Route>
              <Route path="/dashboard" element={<LayoutDashboard />}></Route>
              <Route path="/setting/*" element={<LayoutSetting />}></Route>
              <Route path="/privacy" element={<LayoutPrivacy />}></Route>
              <Route path="/tos" element={<LayoutTermsNCondition />}></Route>
            </>
          ) : (
            <></>
          )}
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          // transition:Bounce
        />
      </LoginContext.Provider>
    </div>
  );
}
export default App;
