import React from "react";
import loaderWebm from "../../Utilities/loader.webm";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      <video
        style={{
          width: "45px",
          height: "45px",
        }}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={loaderWebm} type="video/webm" />
      </video>
    </div>
  );
};

export default Loader;
