const ENV_PROD = true; // For proudction make it true, don't change in the local environment
let minsky_url,
  minsky_vectorDB = "";

if (ENV_PROD) {
  minsky_url = "https://api.ai.ml";
  // minsky_vectorDB = "https://api.minsky.app/vectordb"
} else {
  minsky_url = "https://103.177.43.219:8085";
  // minsky_vectorDB = "https://uat.minsky.app/vectordb";
}

const API_LIVE = {
  BASE_URL_MINSKY: minsky_url,

  //----------------------------------------------------------
  login: `${minsky_url}/user/login/`,
  register: `${minsky_url}/user/register/`,
  forgetPassword: `${minsky_url}/user/forgot/password`,
  changePassword: `${minsky_url}/user/change/password/`,
  textToTextStreaming: `${minsky_url}/text-to-streaming/generate-request/`,
  textToText: `${minsky_url}/text-to-text/generate-request/`,
  speechToText: `${minsky_url}/speech-to-text/generate-request/`,
  textToSpeech: `${minsky_url}/text-to-speech/generate-request/`,
  updateRecord: `${minsky_url}/user/update/record/`,
  generateAPIKey: `${minsky_url}/user/update/key/`,
  userBalance: `${minsky_url}/user/balance`,
  GetModelOptions: `${minsky_url}/track/method/`,
  TrackUserLogs: `${minsky_url}/track/logs/`,
  updatePrompt: `${minsky_url}/text-to-text/update/prompt/`,
  deletePrompt: `${minsky_url}/text-to-text/delete/prompt/`,
  createPrompt: `${minsky_url}/text-to-text/create/prompt/`,
  getPrompt: `${minsky_url}/text-to-text/get/prompt/`,
  textToTextModelNames: `${minsky_url}/text-to-text/model/`,
  textToTextModelTypes: `${minsky_url}/text-to-text/model/type/`,
  textToSpeechLanguage: `${minsky_url}/text-to-speech/language/`,
  speechToTextModel: `${minsky_url}/speech-to-text/model/`,
  textToSpeechModel: `${minsky_url}/text-to-speech/model/`,
  completeMeter: `${minsky_url}/track/meters-events/`,
  modelTypes: `${minsky_url}/text-to-text/model/`,
  downloadBill: `${minsky_url}/track/download-bill/`,
  promptConversationDataAPI: `${minsky_url}/text-to-text/conversation/`,
  memberInviteApi: `${minsky_url}/groups/send-invite/`,
  deleteMemberApi: `${minsky_url}/groups/remove-user/`,
  yourOrgApi: `${minsky_url}/groups/create/`,
  orgDataApi: `${minsky_url}/groups/info/`,
  crateApiKey: `${minsky_url}/user/key-register/`,
  getApiKeyData: `${minsky_url}/user/key-info/`,
  apiKeyDelete: `${minsky_url}/user/update/key/`,
  dashboardJsonPath:
    "https://neurobridge-public.objectstore.e2enetworks.net/aiml/ai-ml-dashboard.json",
  memeberDataApi: `${minsky_url}/groups/invited-user/`,
  orgCheckDataApi: `${minsky_url}/user/login/`,
  newConversationApi: `${minsky_url}/text-to-text/new-conversation/`,

  //-----------------------------------------------------------------------

  //Vector DB
  getFoldersInfo: `${minsky_vectorDB}/list/partition/info/`,
  uploadURLs: `${minsky_vectorDB}/upload-file/url/`,
  uploadFile: `${minsky_vectorDB}/upload-file`,
  addFolderVectorDB: `${minsky_vectorDB}/list/create/partition`,
  getFileRecords: `${minsky_vectorDB}/list/get/record`,
  deleteSingleFile: `${minsky_vectorDB}/list/delete/record/`,
};

const MINSKY_URL = API_LIVE;

export default MINSKY_URL;
