import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import * as Sentry from "@sentry/react";

const initialState = {
  passwordChange: {},

  recordUpdate: {
    first_name: "",
    last_name: "",
  },
};

export const changePassword = createAsyncThunk(
  "password/ChangePassword",
  async ({ requestBody }) => {
    try {
      const response = await axiosInstance.post(
        `${MINSKY_URL.changePassword}`,
        requestBody
      );
      if (response.status === 500) {
        Sentry.captureException("Server Error");
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        Sentry.captureException("Server Error");
      }
      return error.response.data;
    }
  }
);

export const updateRecord = createAsyncThunk(
  "update/updateRecord",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.updateRecord}`, requestBody)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const profileSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(changePassword.fulfilled, (state, action) => {})
      .addCase(changePassword.rejected, (state, action) => {
        state.status = "failed";
      })

      //--------------------------------------------------

      .addCase(updateRecord.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(updateRecord.fulfilled, (state, action) => {})
      .addCase(updateRecord.rejected, (state, action) => {
        state.status = "failed";
      });

    //-----------------------------------------------
  },
});

// export const {} = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
