import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, Input, Modal, Select, Table, Tag } from "antd";
import { PlusOutlined, StopOutlined } from "@ant-design/icons";
import deleteIcon from "../../Assets/deleteIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getMemberDataAction,
  getMemberDeleteDataAction,
  memberInviteAction,
} from "../../slices/settingsSlice";
import moment from "moment";
import Loader from "../../Reusable Components/Loader/Loader";

export const MembersInvites = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [openInvite, setOpenInvite] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [email, setEmail] = useState("");
  const [userRole, setUserRole] = useState("");
  // const [name, setName] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [userEmail, setUserEmail] = useState("");

  // const { memberInviteData } = useSelector(
  //   (state) => state.setting.memberInvitee.memberInviteData
  // );

  const memberData = useSelector(
    (state) => state.setting.getMemberData.memberData
  );

  const isMemberDataLoading = useSelector(
    (state) => state.setting.getMemberData.isMemberDataLoading
  );

  const orgDataCheck = useSelector(
    (state) => state.setting.orgCheckData.orgDataCheck
  );

  const TableDataColumns = [
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      // width: 400,
    },
    {
      title: "STATUS",
      dataIndex: "invites_status",
      key: "invites_status",
      render: (_, record) => {
        return (
          <>
            {record?.invites_status ? (
              <Tag
                className="p-1 px-3 rounded-5"
                style={{
                  color: `${
                    record?.invites_status === "Pending" ? "#FF7A00" : "#6df51b"
                  }`,
                  backgroundColor: `${
                    record?.invites_status === "Pending"
                      ? "rgba(255, 122, 0, 0.12)"
                      : "rgb(224, 247, 210)"
                  }`,
                  fontWeight: 500,
                }}
              >
                {record?.invites_status?.toUpperCase()}
              </Tag>
            ) : null}
          </>
        );
      },
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      render: (_, record) =>
        moment(record?.created_at).format("DD/MM/YYYY, hh:mm:ss"),
      key: "created_at",
      // width: 400,
    },
    // {
    //   title: "ROLE",
    //   dataIndex: "role",
    //   key: "role",
    // },
    {
      title: "",
      dataIndex: "action",
      key: "delete",
      render: (_, record) => {
        return (
          <div
            className="cursor-pointer d-flex justify-content-end"
            role="button"
            onClick={() => showModalDelete(record?.email)}
          >
            {/* {record?.invites_status !== "Pending" ? ( */}
            <img src={deleteIcon} alt="deleteIcon" className="w-full h-full" />
            {/* ) : null} */}
          </div>
        );
      },
    },
  ];

  const showModal = () => {
    setOpenInvite(true);
  };
  const showModalDelete = (emailId) => {
    setUserEmail(emailId);
    setOpenDelete(true);
  };
  const handleOk = () => {
    const payload = {
      invitee_email: email,
      // invitee_name: name,
      invitee_role: userRole,
    };
    dispatch(memberInviteAction({ payload, handleInitaldata }));
    setOpenInvite(false);
    setEmailErr(null);
  };
  const handleOkDelete = () => {
    const payload = {
      email: userEmail,
    };
    dispatch(getMemberDeleteDataAction({ payload, handleInitaldata }));
    setOpenDelete(false);
  };
  const handleCancel = () => {
    setEmail("");
    // setName("");
    setUserRole("");
    setOpenInvite(false);
    setEmailErr(null);
  };
  const handleCancelDelete = () => {
    setOpenDelete(false);
  };
  const handleUserRoleChange = (value) => {
    setUserRole(value);
  };

  useEffect(() => {
    setCount(memberData?.data?.length);
  }, [memberData?.data?.length]);

  useEffect(() => {
    let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (email) {
      if (emailReg.test(email)) {
        setEmailErr(null);
      } else {
        setEmailErr("* enter a valid email");
      }
    }
  }, [email]);

  const handleInitaldata = () => {
    const payload = {
      status: "",
    };
    dispatch(getMemberDataAction(payload));
  };

  useEffect(() => {
    const payload = {
      status: "",
    };
    dispatch(getMemberDataAction(payload));
  }, [dispatch]);

  return (
    <div className="aiml-card your-organisation">
      <div className="ailm-card-header">
        <div className="d-flex justify-content-start align-items-center">
          <h3 className="card-title mx-3 Onest">Members</h3>
          <div
            className="p-1 px-2 rounded-circle"
            style={{ backgroundColor: "#E9E9E9" }}
          >
            {count ? count : 0}
          </div>
        </div>
        <Button
          type="primary"
          className="aiml-primary-btn"
          icon={<PlusOutlined />}
          onClick={showModal}
          disabled={orgDataCheck?.is_assigned === true}
        >
          invite
        </Button>
      </div>
      <div className="aiml-card-body Onest">
        {!isMemberDataLoading ? (
          memberData?.data?.length > 0 ? (
            <Table
              dataSource={memberData?.data}
              columns={TableDataColumns}
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                position: ["bottomCenter"],
                pageSizeOptions: ["10", "20", "30"],
              }}
              className="aiml-table"
            />
          ) : (
            <div>No Data Available</div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 180,
            }}
          >
            <Loader />
          </div>
        )}
      </div>
      <Modal
        open={openInvite}
        title="Invite member"
        wrapClassName="aiml-modal-dialog"
        onCancel={handleCancel}
        onOk={handleOk}
        okText="Send Invite"
        width={415}
      >
        <div>
          <div className="fInput-group my-3">
            <label className="input-label">Email to invite</label>
            <Input
              placeholder="Enter your email"
              className="form-input"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <div className="email-error">{emailErr}</div>
          </div>
          {/* <div className="fInput-group my-3">
            <label className="input-label">Name to invite</label>
            <Input
              placeholder="Enter your name"
              className="form-input"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div> */}
          <div className="fInput-group my-3">
            <label className="input-label">Role & permissions</label>
            <Select
              defaultValue={userRole}
              disabled={email === "" || emailErr}
              onChange={handleUserRoleChange}
              style={{
                width: "100%",
              }}
              options={[
                {
                  value: "",
                  label: "Select Role",
                },
                {
                  value: "admin",
                  label: "Admin",
                },
                {
                  value: "user",
                  label: "User",
                },
              ]}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={openDelete}
        title="Revoke invitation?"
        onCancel={handleCancelDelete}
        wrapClassName="aiml-modal-dialog"
        className="aiml-revoke-modal"
        width={415}
        okText={
          <>
            <StopOutlined /> Revoke
          </>
        }
        onOk={handleOkDelete}
      >
        <div className="my-4">
          Are you sure you want to revoke the invitation for {userEmail}
        </div>
      </Modal>
    </div>
  );
};
