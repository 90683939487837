import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { completeMeterEvents } from "../../slices/analyticsSlice";
import Loader from "../../Reusable Components/Loader/Loader";

const Logs = () => {
  const [lastRefreshTime, setLastRefreshTime] = useState(new Date());
  const completeMeterData = useSelector(
    (state) => state.analytics.completeMeter.completeMeterData
  );
  const completeMeterLoading = useSelector(
    (state) => state.analytics.completeMeter.isCompleteMeterLoading
  );

  const dispatch = useDispatch();

  const dataArray = Array.isArray(completeMeterData?.events?.ai_ml_chat)
    ? completeMeterData?.events?.ai_ml_chat
    : [completeMeterData?.events?.ai_ml_chat];

  const columns = [
    {
      title: "TIME (UTC)",
      dataIndex: "time",
      key: "time",
      render: (text) => moment(text).format("DD-MM-YYYY, HH:mm:ss"),
      // width: 200,
    },
    {
      title: "MODEL",
      dataIndex: "model_name",
      key: "model_name",
      // width: 225,
    },
    // {
    //     title: "MODEL LATENCY",
    //     dataIndex: "",
    //     key: "language",
    // },
    {
      title: "INPUT TOKENS",
      dataIndex: "prompt_token",
      render: (record) => (record ? record : "-"),
      key: "text_length",
    },
    {
      title: "OUTPUT TOKENS",
      dataIndex: "completion_token",
      render: (record) => (record ? record : "-"),
      key: "text_length",
    },
    // {
    //     title: "TYPE",
    //     dataIndex: "request_type",
    //     key: "type",
    //     width: 200
    // },
    // {
    //     title: "ERROR",
    //     dataIndex: "error",
    //     key: "error",
    //     width: 200
    // },
  ];

  useEffect(() => {
    const date = moment(new Date()).format("YYYY-MM-DD");
    const dateFrom = moment().subtract(3, "days").format("YYYY-MM-DD");

    dispatch(
      completeMeterEvents({
        model: "",
        toDate: date,
        fromDate: dateFrom,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      setLastRefreshTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const formattedLastRefreshTime = lastRefreshTime.toLocaleString("en-US", {
    timeZone: "Asia/Kolkata", // Set your desired timezone here
    hour12: true,
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  });

  return (
    <div className="aiml-card your-organisation">
      <div className="ailm-card-header">
        <div className="d-flex justify-content-between align-items-center w-100">
          <h3 className="card-title">Usage logs</h3>
          <div>Last refresh time: {formattedLastRefreshTime}</div>
        </div>
      </div>
      <div className="aiml-card-body">
        {!completeMeterLoading ? (
          dataArray.length > 0 ? (
            <Table
              dataSource={dataArray}
              columns={columns}
              className="aiml-table"
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                position: ["bottomCenter"],
                pageSizeOptions: ["10", "20", "30"],
              }}
            />
          ) : (
            <div>No Data Available</div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 180,
            }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Logs;
