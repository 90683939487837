import React, { useState } from "react";
import { Modal, Tabs, Button } from "antd";
import CopyIcon from "../Assets/copy_icon.svg";
import MarkdownRenderer from "../Reusable Components/MarkdownRenderer";
import blueTick from "../Assets/blueTick.svg";

export const CodeModal = ({ open, onCancel }) => {
  const [dataCopiedPython, setDataCopiedPython] = useState(false);
  const [dataCopiedJs, setDataCopiedJS] = useState(false);
  const [dataCopiedCurl, setDataCopiedCurl] = useState(false);

  const handleDataCopy = (data) => {
    if (data === pythonCode) {
      navigator.clipboard.writeText(
        pythonCode.replace(/^\s*```python\s*/, "").replace(/\s*```\s*$/, "")
      );
      setDataCopiedPython(true);
    } else if (data === jsCode) {
      navigator.clipboard.writeText(
        jsCode.replace(/^\s*```js\s*/, "").replace(/\s*```\s*$/, "")
      );
      setDataCopiedJS(true);
    } else if (data === curlCode) {
      navigator.clipboard.writeText(
        curlCode.replace(/^\s*```bash\s*/, "").replace(/\s*```\s*$/, "")
      );
      setDataCopiedCurl(true);
    }
  };

  const pythonCode = `
  \`\`\`python
  import requests
  import json
  url = "https://api.ai.ml/ai-ml/"
  payload = json.dumps({
      "conversation": "",
      "model": "Mistral 7B",
      "query": "<s>[INST] <<SYS>>You are an assistant and your task is to help with user queries<</SYS>>what is langchain?[/INST]",
      "max_tokens": 512,
      "stop": "</s>",
      "temperature": 0.7,
      "top_p": 0.7,
      "top_k": 50,
      "repetition_penalty": 1,
      "is_stream": True
  })
  headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer aiml-********************************************'
  }
  response = requests.request("POST", url, headers=headers, data=payload)
  print(response.text)
  \`\`\`
  `;

  const jsCode = `
  \`\`\`js
const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "Bearer aiml-********************************************");

const raw = JSON.stringify({
  "conversation": "",
  "model": "Mistral 7B",
  "query": "<s>[INST] <<SYS>>You are an assistant and your task is to help with user queries<</SYS>>what is langchain?[/INST]",
  "max_tokens": 512,
  "stop": "</s>",
  "temperature": 0.7,
  "top_p": 0.7,
  "top_k": 50,
  "repetition_penalty": 1,
  "is_stream": true
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch("https://api.ai.ml/ai-ml/", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
  \`\`\`
`;

  const curlCode = `
\`\`\`bash
curl --location 'https://api.ai.ml/ai-ml/' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer aiml-******' \\
--data '{
    "conversation": "",
    "model": "Mistral 7B",
    "query": "<s>[INST] <<SYS>>You are an assistant and your task is to help with user queries<</SYS>>what is langchain?[/INST]",
    "max_tokens": 512,
    "stop": "</s>",
    "temperature": 0.7,
    "top_p": 0.7,
    "top_k": 50,
    "repetition_penalty": 1,
    "is_stream": true
}'
\`\`\`
`;

  return (
    <Modal
      title="Code for AI.ML API"
      className="copy-code-modal"
      wrapClassName="aiml-modal-dialog"
      width={730}
      footer={null}
      open={open}
      onCancel={onCancel}
      animationDuration={2}
    >
      <Tabs
        className="copy-code-tabs-main"
        defaultActiveKey="1"
        items={[
          {
            label: "Python",
            key: "1",
            children: (
              <div className="tab-code-body">
                <MarkdownRenderer content={pythonCode} />
                {dataCopiedPython ? (
                  <p className="copied">
                    Copied! <img src={blueTick} alt="copied" />
                  </p>
                ) : (
                  <Button
                    className="copy-btn"
                    onClick={() => {
                      handleDataCopy(pythonCode);
                    }}
                  >
                    <img src={CopyIcon} alt="copyBtn" />
                  </Button>
                )}
              </div>
            ),
          },
          {
            label: "JS",
            key: "2",
            children: (
              <div className="tab-code-body">
                <MarkdownRenderer content={jsCode} />
                {dataCopiedJs ? (
                  <p className="copied">
                    Copied! <img src={blueTick} alt="copied" />
                  </p>
                ) : (
                  <Button
                    className="copy-btn"
                    onClick={() => {
                      handleDataCopy(jsCode);
                    }}
                  >
                    <img src={CopyIcon} alt="copyBtn" />
                  </Button>
                )}
              </div>
            ),
          },
          {
            label: "Curl",
            key: "3",
            children: (
              <div className="tab-code-body">
                <MarkdownRenderer content={curlCode} />
                {dataCopiedCurl ? (
                  <p className="copied">
                    Copied! <img src={blueTick} alt="copied" />
                  </p>
                ) : (
                  <Button
                    className="copy-btn"
                    onClick={() => {
                      handleDataCopy(curlCode);
                    }}
                  >
                    <img src={CopyIcon} alt="copyBtn" />
                  </Button>
                )}
              </div>
            ),
          },
        ]}
      />
    </Modal>
  );
};
