import React, { useState, useContext, useEffect } from "react";
import "./Layout.css";
import { Layout, Menu, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginContext } from "../ContextApis/LoginContext";
import SettingsRouts from "../Navigation/SettingsRouts";
import Cookies from "js-cookie";
// import { useDispatch, } from "react-redux";

import KeycloakService from "../Utilities/KeycloakService";
// import Bar_chart from "../Assets/bar_chart.svg";
const assetPath = "https://neurobridge-public.objectstore.e2enetworks.net";
const Logo = assetPath + "/aiml/logo.svg";
const Logout = assetPath + "/aiml/logout.svg";
const Organisation = assetPath + "/aiml/organisation.svg";
const UserPlus = assetPath + "/aiml/user_plus.svg";
const Card = assetPath + "/aiml/card.svg";
const Keys = assetPath + "/aiml/keys.svg";
const Files = assetPath + "/aiml/files.svg";
const User_Small = assetPath + "/aiml/user_small.svg";
const Organisation_Outline = assetPath + "/aiml/organisation_outline.svg";
const Terms_Outline = assetPath + "/aiml/terms_outline.svg";
const User_plus_Outline = assetPath + "/aiml/user_outline_plus.svg";
const Card_Outline = assetPath + "/aiml/card_outline.svg";
const Key_Outline = assetPath + "/aiml/key_outline.svg";
const File_Outline = assetPath + "/aiml/file_outline.svg";
const Privacy_Outline = assetPath + "/aiml/privacy_outline.svg";
const { Header, Content } = Layout;

const LayoutSetting = () => {
  // const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [selectedKey, setSelectedKey] = useState("yourOrganisation");
  const [userDetail, setUserDetail] = useState(null);
  const {
    setUserId,
    setIsAuthenticated,
    setFirstName,
    setLastName,
    setProfileEmail,
    setProfilePhone,
    setAuthData,
    handleLogoutUser,
  } = useContext(LoginContext);
  const { keycloak } = KeycloakService;
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const retrive = async () => {
      try {
        const info = await keycloak.loadUserProfile();
        setUserDetail(info);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    retrive();
  }, [keycloak]);

  const handleDashboardNavigation = () => {
    navigate("/dashboard");
  };

  const handleProfileNavigation = () => {
    navigate(`/playground/chat`);
  };
  const navigateAPIDoc = (tab) => {
    if (tab === "docs") {
      window.open("http://api.ai.ml/docs/swagger/", "_blank");
    } else {
      window.open("https://community.ai.ml/", "_blank");
    }
  };
  const handleLogoutDrop = () => {
    setIsActive(!isActive);
  };
  // const handleProfileBalanceClick = () => {
  //   navigate("/dashboard/billing");
  // };
  const handleLogout = () => {
    sessionStorage.clear();
    // Cookies.remove("keyCloakToken", keyCloakToken);
    setIsAuthenticated(false);
    setAuthData(false);
    setFirstName(false);
    setLastName(false);
    setProfileEmail(false);
    setProfilePhone(false);
    setUserId(false);
    handleLogoutUser();
    const cookies = Cookies.get();

    // Iterate over all cookies and remove each one
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
    // window.location.reload();
    navigate("/");
  };
  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
    navigate(key);
  };
  const handleCloseDropdown = () => {
    setIsActive(false);
  };
  const handleMenuClickDropdown = (key) => {
    if (key === "privacy" || key === "tos") {
      navigate(`/${key}`);
    } else {
      navigate(`/setting/${key}`);
    }
  };
  useEffect(() => {
    const pathSegments = location?.pathname?.split("/");
    const key = pathSegments[2];
    setSelectedKey(key || "yourOrganisation");
  }, [location]);
  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout style={{ marginLeft: 200 }}>
          <div>
            <Header className="header-wrapper">
              <div className="header-logo">
                <img
                  src={Logo}
                  className="logo"
                  alt="logo"
                  onClick={() => navigate("/dashboard")}
                />
              </div>

              <div className="nav-links">
                <ul>
                  <li>
                    <div
                      className={`footer_sider_bar`}
                      onClick={handleDashboardNavigation}
                    >
                      Dashboard
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div
                      onClick={handleProfileNavigation}
                      className={`footer_sider_bar`}
                    >
                      Playground
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div className={`footer_sider_bar  ${"active"}`}>
                      Settings
                    </div>
                  </li>
                </ul>
              </div>
              <div className="user-settings-main">
                <div
                  onClick={() => navigateAPIDoc("community")}
                  className="docs-cum"
                >
                  Community
                </div>
                <div
                  onClick={() => navigateAPIDoc("docs")}
                  className="docs-cum"
                >
                  Docs
                </div>
                <div className="logout-wrap">
                  <img
                    src={Logout}
                    onClick={handleLogoutDrop}
                    className="logout-icon"
                    alt="logoutIcon"
                  />

                  {isActive ? (
                    <>
                      <div
                        className="drop-backDrop"
                        onClick={handleCloseDropdown}
                      >
                        {/* close */}
                      </div>
                      <div className="drop-list">
                        <div className="drop-header">
                          <img src={User_Small} alt="icon" />
                          <div>
                            <h4 className="drop-title">
                              {userDetail?.username}
                            </h4>
                            <p className="drop-text">{userDetail?.email}</p>
                          </div>
                        </div>
                        <div className="drop-body ">
                          <p
                            className="drop-item "
                            onClick={() =>
                              handleMenuClickDropdown("yourOrganisation")
                            }
                          >
                            <img src={Organisation_Outline} alt="icon" />
                            Your Organisation
                          </p>
                          <p
                            className="drop-item"
                            onClick={() =>
                              handleMenuClickDropdown("membersInvites")
                            }
                          >
                            <img src={User_plus_Outline} alt="icon" />
                            Members & Invites
                          </p>
                          <p
                            className="drop-item"
                            onClick={() =>
                              handleMenuClickDropdown("plansBilling")
                            }
                          >
                            <img src={Card_Outline} alt="icon" />
                            Plan & Pricing
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("apiKeys")}
                          >
                            <img src={Key_Outline} alt="icon" />
                            API Keys
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("logs")}
                          >
                            <img src={File_Outline} alt="icon" />
                            Logs
                          </p>
                          {/* <p className="drop-item">
                          <img src={Data_Outline} alt="icon" />
                          Usage
                        </p> */}
                        </div>
                        <div className="drop-footer">
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("privacy")}
                          >
                            <img src={Privacy_Outline} alt="icon" />
                            Privacy Policy
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("tos")}
                          >
                            <img src={Terms_Outline} alt="icon" />
                            Terms of Service
                          </p>
                          <p
                            className="drop-item logout"
                            onClick={handleLogout}
                          >
                            Logout
                          </p>
                        </div>
                        {/* <li
                    className="drop-item"
                    onClick={handleProfileBalanceClick}
                  >
                    Wallet
                  </li> */}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Header>
          </div>
          <div className="sub-header-wrapper">
            <div className="menu-items-main">
              <Menu
                mode="inline"
                selectedKeys={[selectedKey]}
                onClick={handleMenuClick}
                items={[
                  {
                    key: "yourOrganisation",
                    icon: <img src={Organisation} alt="icon" />,
                    label: "Your Organisation",
                  },
                  {
                    key: "membersInvites",
                    icon: <img src={UserPlus} alt="icon" />,
                    label: "Members & Invites",
                  },
                  {
                    key: "plansBilling",
                    icon: <img src={Card} alt="icon" />,
                    label: "Plans & Billing",
                  },
                  {
                    key: "apiKeys",
                    icon: <img src={Keys} alt="icon" />,
                    label: "API Keys",
                  },
                  {
                    key: "logs",
                    icon: <img src={Files} alt="icon" />,
                    label: "Logs",
                  },
                  // {
                  //   key: "usage",
                  //   icon: <img src={Bar_chart} alt="icon" />,
                  //   label: "Usage",
                  // },
                ]}
              />
            </div>
          </div>
          <Content
            style={{
              margin: "24px 16px",
              marginTop: "0px",
              padding: selectedKey === "plansBilling" ? 0 : 24,
              minHeight: 280,
              background:
                selectedKey === "plansBilling" ? null : colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <SettingsRouts />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default LayoutSetting;
