import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  dashbaoardJsonData: {
    isDashboardJsonDataLoading: false,
    dashboardData: null,
  },
};

export const dashboardJsonDataAction = createAsyncThunk(
  "dashbaoardJsonData/dashboardJsonDataAction",
  async () => {
    try {
      const response = await axiosInstance.get(
        `${MINSKY_URL.dashboardJsonPath}`
      );
      if (response.status === 200) {
        return response.data; // Return the PDF content
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      // Log error to Sentry
      Sentry.captureException(error);
      throw error; // Rethrow the error for handling in the component
    }
  }
);

export const dashboardSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dashboardJsonDataAction.pending, (state, action) => {
        state.status = "pending";
        state.dashbaoardJsonData.isDashboardJsonDataLoading = true;
      })
      .addCase(dashboardJsonDataAction.fulfilled, (state, action) => {
        state.dashbaoardJsonData.isDashboardJsonDataLoading = false;
        state.dashbaoardJsonData.dashboardData = action.payload;
      })
      .addCase(dashboardJsonDataAction.rejected, (state, action) => {
        state.status = "failed";
        state.dashbaoardJsonData.isDashboardJsonDataLoading = false;
      });
  },
});

// export const {} = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
