import { boot, shutdown, show } from "@intercom/messenger-js-sdk";

const intercomAppId = "hyitk9q2";

const intercom = {
  boot: () => {
    boot({ app_id: intercomAppId });
  },
  shutdown: () => {
    shutdown();
  },
  show: () => {
    show();
  },
};

export default intercom;
