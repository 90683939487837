import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Button, Spin, Modal, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { findIndex } from "lodash";
import { SSE } from "sse.js";
import ReactMarkdown from "react-markdown";
import { ModelContext } from "./ContextApis/ModelContext";
import Mic from "./Assets/mic.svg";
// import pencil from "./Assets/pencil.svg";
import Bot_logo from "./Assets/bot_logo.svg";
import clearIcon from "./Assets/Frame.svg";
import SendIcon from "./Assets/sendMessage.svg";
import SoundWave from "./Assets/sound_wave.svg";
import "./ChatToText.css";
import MarkdownRenderer from "./Reusable Components/MarkdownRenderer";
import Cookies from "js-cookie";
import {
  clearPreviousReducer,
  createNewConversationIdAction,
  getPromptConversationDataAction,
  updateReducerAction,
} from "./slices/modelSlice";

export const ChatToText = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.auth.login);
  const { isLoading } = useSelector((state) => state.speech.chatToText);
  const [messageData, setMessage] = useState("");
  const [text, setText] = useState(0);
  const [listening, setListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conversationIdStatus, setConversationIdStatus] = useState(false);
  const [messageDataCapture, setMessageDataCapture] = useState("");
  const {
    // setselectedModelName,
    selectedModelName,
    outputLengthRange,
    temperatureRange,
    topPRange,
    topKRange,
    repetitionPenaltyRange,
    sysInstructionPrompt,
    tokenParsedData,
    setMessages,
    messages,
    conversationHistoryId,
    setConversationHistoryId,
    selectedMenu,
    previousMenu,
    setPreviousMenu,
    previousModelName,
    setPreviousModelName,
    eventSourceRef,
  } = useContext(ModelContext);

  const { newConversation } = useSelector((state) => state.model);
  // const { showConversationData } = useSelector((state) => state.model);

  const textareaRef = useRef(null);
  const chatBodyRef = useRef(null);

  const [autoScroll, setAutoScroll] = useState(true);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      sendMessage();
      e.preventDefault();
    } else if (e.keyCode === 13 && e.shiftKey) {
      e.preventDefault();
      setMessage((prevMessage) => `${prevMessage}\n`);
      setMessageDataCapture((prevMessage) => `${prevMessage}\n`);
    }
  };
  const handleMessageHandler = (e) => {
    setMessage(e.target.value);
    setMessageDataCapture(e.target.value);
    setText(e.target.value.length);
  };
  const handleModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const resetChat = () => {
    setMessages([]);
    setMessage("");
    setMessageDataCapture("");
    setText("");
    dispatch(clearPreviousReducer());
    setConversationHistoryId("");
    const payload = {
      limit: 5,
    };
    dispatch(getPromptConversationDataAction(payload));
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setMessages([]);
    setText("");
  }, [selectedModelName]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // const api_key = data?.api_key;

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleMessage = useCallback((e, eventSource) => {
    if (e.data !== "[DONE]") {
      const payload = JSON.parse(e.data);
      const botMessage = payload.choices[0].text;
      const botMessageId = payload.id;
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        const existingIndex = findIndex(newMessages, ["id", botMessageId]);
        if (existingIndex === -1) {
          newMessages.push({
            id: botMessageId,
            text: botMessage,
            isBot: true,
          });
        } else {
          newMessages[existingIndex].text += botMessage;
        }
        return newMessages;
      });
    } else {
      eventSource.close();
      setConversationIdStatus(false);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  console.log(
    "previous",
    previousMenu,
    "selected",
    selectedMenu,
    previousModelName?.model_name,
    selectedModelName?.model_name
  );

  const handleCallBack = (id) => {
    const keyCloakToken = Cookies.get("keyCloakToken");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${keyCloakToken}`);
    const url = "https://api.ai.ml/ai-ml/";
    const data = JSON.stringify({
      model: selectedModelName.model_id,
      query: `${
        sysInstructionPrompt
          ? sysInstructionPrompt
          : selectedModelName.start_prompt
      }${messageDataCapture}${selectedModelName.end_prompt}`,
      max_tokens: outputLengthRange[0],
      temperature: temperatureRange[0],
      top_p: topPRange[0],
      top_k: topKRange[0],
      repetition_penalty: repetitionPenaltyRange[0],
      is_stream: true,
      stop: selectedModelName?.stop_seq,
      conversation: id,
    });
    try {
      eventSourceRef.current = new SSE(url, {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: `Bearer ${keyCloakToken}`,
        },
        method: "POST",
        payload: data,
      });
      eventSourceRef.current.addEventListener("message", (e) => {
        handleMessage(e, eventSourceRef.current);

        // setMessage("");
        setMessageDataCapture("");
        setText("");
      });
      eventSourceRef.current.addEventListener("error", (error) => {
        console.error("eventSourceRef.current error:", error);
        eventSourceRef.current.close();
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sendMessage = () => {
    if (messageData?.trim() !== "") {
      if (newConversation === null) {
        dispatch(createNewConversationIdAction(handleCallBack));
        setMessages([]);
      }
      if (recognition && listening) {
        recognition.stop();
      }
      const newMessage = {
        id: (Math.random() + 1).toString(36).substring(7),
        text: messageData,
        isBot: false,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setConversationIdStatus(true);
      setMessage("");
      setText("");
      setPreviousMenu(selectedMenu);
      setPreviousModelName(selectedModelName);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setMessages([]);
  }, [selectedMenu, selectedModelName]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (messageData.trim() !== "" && conversationHistoryId !== "") {
      dispatch(
        updateReducerAction({
          status: true,
          conversation_id: conversationHistoryId,
        })
      );
      setConversationHistoryId("");
    }
  }, [messageData]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (conversationIdStatus === true && newConversation !== null) {
      const keyCloakToken = Cookies.get("keyCloakToken");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${keyCloakToken}`);
      const url = "https://api.ai.ml/ai-ml/";
      const data = JSON.stringify({
        model: selectedModelName.model_id,
        query: `${
          sysInstructionPrompt
            ? sysInstructionPrompt
            : selectedModelName.start_prompt
        }${messageDataCapture}${selectedModelName.end_prompt}`,
        max_tokens: outputLengthRange[0],
        temperature: temperatureRange[0],
        top_p: topPRange[0],
        top_k: topKRange[0],
        repetition_penalty: repetitionPenaltyRange[0],
        is_stream: true,
        stop: selectedModelName?.stop_seq,
        conversation: newConversation?.conversation_id,
      });
      try {
        eventSourceRef.current = new SSE(url, {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${keyCloakToken}`,
          },
          method: "POST",
          payload: data,
        });
        eventSourceRef.current.addEventListener("message", (e) => {
          handleMessage(e, eventSourceRef.current);
          // setMessage("");
          setMessageDataCapture("");
          setText("");
        });
        eventSourceRef.current.addEventListener("error", (error) => {
          console.error("eventSourceRef.current error:", error);
          eventSourceRef.current.close();
        });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }, [conversationIdStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (previousMenu !== selectedMenu && eventSourceRef.current) {
      eventSourceRef.current.close();
    }
    if (previousModelName !== selectedModelName && eventSourceRef.current) {
      eventSourceRef.current.close();
    }
  }, [previousMenu, selectedMenu, previousModelName, selectedModelName]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognitionInstance = new SpeechRecognition();
    recognitionInstance.continuous = true;

    recognitionInstance.onstart = () => setListening(true);
    recognitionInstance.onend = () => setListening(false);
    recognitionInstance.onresult = (event) => {
      const currentTranscript =
        event.results[event.results.length - 1][0].transcript;
      setMessage((prevMessage) => `${prevMessage} ${currentTranscript}`);
      setMessageDataCapture(
        (prevMessage) => `${prevMessage} ${currentTranscript}`
      );
      setText(
        (prevText) => Number(prevText) + Number(currentTranscript.length)
      );
    };
    setRecognition(recognitionInstance);
    return () => {
      if (recognitionInstance) {
        recognitionInstance.stop();
      }
    };
  }, []);

  const toggleListening = () => {
    if (recognition) {
      if (listening) {
        recognition.stop();
      } else {
        recognition.start();
      }
    }
  };

  const scrollToBottom = useCallback(() => {
    if (chatBodyRef.current && autoScroll) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [autoScroll]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const handleScroll = () => {
    if (chatBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBodyRef.current;
      const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 10;
      setAutoScroll(isAtBottom);
    }
  };

  const joinedMessages = messages
    .filter((msg) => msg.isBot)
    .map((msg) => msg.text)
    .join("");
  const renderedMessages = messages.map((msg, index) => (
    <Message
      key={index}
      message={msg}
      data={data}
      joinedMessages={joinedMessages}
      tokenParsedData={tokenParsedData}
    />
  ));

  console.log("renderedMessages", renderedMessages);

  return (
    <div className="chat-container">
      <div className="prompt-header">
        <h4 className="chat-title">
          {selectedModelName?.model_name?.toUpperCase()}
          <span className="custom-span" onClick={handleModal}>
            {/* <img src={pencil} alt="ai.ml" width={16} height={16} /> */}
          </span>
        </h4>
        {messages?.length > 0 && (
          <div className="clear-chat-container" onClick={resetChat}>
            <img
              src={clearIcon}
              className="delete-chat"
              alt="ai.ml"
              width={16}
              height={16}
            />
            <div className="clear-chat">Clear</div>
          </div>
        )}
      </div>
      <div className="chats-body-container">
        {renderedMessages?.length > 0 ? (
          <div className="chat-body" ref={chatBodyRef} onScroll={handleScroll}>
            {renderedMessages}{" "}
          </div>
        ) : (
          <div className="welcome-body">
            <h5 className="welcome-text">
              Hello,{" "}
              <span className="username">{`${
                tokenParsedData ? tokenParsedData : ""
              }`}</span>
              <br /> Explore ai.ml playground here
            </h5>
          </div>
        )}
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 180,
          }}
        >
          <Spin />
        </div>
      )}
      <div className="input-container-footer">
        <div className="textarea-wrapper">
          {listening && (
            <div className="listen-text-box">
              <p className="listening-text">
                <span>{messageData ? messageData : "Speak to type"}</span>
                <img src={SoundWave} alt="icon" />
              </p>
            </div>
          )}
          <textarea
            ref={textareaRef}
            className="Chat-text-field"
            value={listening ? "" : messageData}
            onChange={handleMessageHandler}
            placeholder={`${listening ? "" : "Enter Text here..."}`}
            onKeyDown={handleKeyDown}
            maxLength={selectedModelName?.max_seq}
          />
          <div className="query-button-container">
            <button
              onClick={toggleListening}
              className={`mic-logo ${listening ? "listening" : ""}`}
            >
              <img src={Mic} alt="mic logo" />
            </button>
            <div className="btn-text-box">
              <span className="word-limit">
                {`${text ? text : "0"} / ${
                  selectedModelName ? selectedModelName.max_seq : "4000"
                }`}
              </span>
              <Button
                className="chat-send-tooltip"
                onClick={sendMessage}
                type="button"
                title="Run"
              >
                Run
                <img src={SendIcon} alt="send" width={12} height={12} />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Rename your prompt"
        okText="Save"
        wrapClassName="aiml-modal-dialog"
        width={400}
        open={isModalOpen}
        onCancel={handleCloseModal}
      >
        <div className="fInput-group">
          <Input placeholder="Enter Prompt Name" className="form-input" />
        </div>
      </Modal>
    </div>
  );
};

const Message = ({ message, tokenParsedData }) => {
  const { isBot, text } = message;
  const joinedMessages = message.isBot ? message.text : "";
  return (
    <>
      {!isBot && (
        <div className="user-message">
          <div className="user-logo">
            <UserOutlined />
          </div>
          <div className="user-name-main">
            <h5 className="user-name">{tokenParsedData}</h5>
            <ReactMarkdown>{text}</ReactMarkdown>
          </div>
        </div>
      )}
      {isBot && (
        <div className="bot-message">
          <div className="user-logo">
            <img src={Bot_logo} alt="bot_logo" />
          </div>
          <div className="user-name-main">
            <h5 className="user-name">{`Model`}</h5>
            <div className="bot-msg-container">
              <MarkdownRenderer content={joinedMessages} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
