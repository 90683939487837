import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth";
import { profileReducer } from "./slices/profileSlice";
import { generateKeyReducer } from "./slices/generateKeySlice";
import { speechReducer } from "./slices/speechSlice";
import { walletReducer } from "./slices/walletSlice";
import { analyticsReducer } from "./slices/analyticsSlice";
import { modelReducer } from "./slices/modelSlice";
import { knowledgeReducer } from "./slices/knowledgeSlice";
import { settingsReducer } from "./slices/settingsSlice";
import { dashboardReducer } from "./slices/dashboardSlice";

const reducer = {
  auth: authReducer,
  profile: profileReducer,
  generateKey: generateKeyReducer,
  speech: speechReducer,
  wallet: walletReducer,
  analytics: analyticsReducer,
  model: modelReducer,
  knowledge: knowledgeReducer,
  setting: settingsReducer,
  dashboard: dashboardReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
});

export default store;
