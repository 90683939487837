import { LeftOutlined } from "@ant-design/icons";
import { Button, Collapse, Modal } from "antd";
import React, { useState } from "react";
import "./style.css";
// import ProgressBar from "@ramonak/react-progress-bar";
import Sparkle from "../../Assets/sparkle.svg";
import { Home } from "../../Payments/Home";
const PlansBilling = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkoutModalVisible, setCheckoutModalVisible] = useState(false);
  // const showModal = () => {
  //   setIsModalVisible(true);
  // };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleButtonClick = () => {
    // Show the modal on button click
    setCheckoutModalVisible(true);
  };

  const handleModalClose = () => {
    setCheckoutModalVisible(false);
  };

  const panelStyle = {
    marginTop: 30,
    marginBottom: 24,
    border: "none",
    fontSize: "16px",
    fontWeight: 600,
  };
  const getItems = (panelStyle) => [
    {
      key: "1",
      label: "How can I update my payment information ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          You can update your payment information by navigating to the “Billing”
          section of your account settings. Here, you can edit your payment
          method and billing details.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: "What payment methods are accepted ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          We accept major credit cards, including Visa, MasterCard, American
          Express, and Discover. Additionally, we also support payments through
          Stripe.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: "How can I view my billing history ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          You can view your billing history by accessing the “Billing” section
          in your account settings. All your past invoices and payment receipts
          will be available for download.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: "What should I do if my payment fails ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          If your payment fails, please check if your payment information is
          correct and that your account has sufficient funds. If the issue
          persists, contact our support team for further assistance.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "5",
      label: "Can I get a refund for my subscription ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          Refunds are handled on a case-by-case basis. Please refer to our
          refund policy in the “Terms of Service” section. For specific refund
          requests, contact our support team.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "6",
      label: "How do I cancel my subscription ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          You can cancel your subscription by going to the “Billing” section in
          your account settings and selecting the “Cancel Subscription” option.
          Please note that cancellation will take effect at the end of your
          current billing cycle.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "7",
      label: "Is there a discount for annual subscriptions ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          Yes, we offer a discount for annual subscriptions. You can save a
          significant amount by opting for an annual plan instead of monthly
          billing. Details are available in the “Billing” section.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "8",
      label: "How is usage billed for AI.ML services ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          Usage is billed based on the plan you have selected and any additional
          resources you consume. Detailed usage and billing information can be
          found in the “Billing” section of your account.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "9",
      label: "How do I add a billing contact to my account ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          To add a billing contact, go to the “Billing” section of your account
          settings and enter the contact details under “Billing Contact
          Information.”
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "10",
      label: "Can I receive invoices in a different currency ?",
      children: (
        <p style={{ fontWeight: 400 }}>
          Currently, we support invoicing in INR. If you need invoices in a
          different currency, please contact our support team to discuss
          possible options.
        </p>
      ),
      style: panelStyle,
    },
  ];
  return (
    <div className="plans-billing px-2">
      <div className="plans-billing-plans-container bg-white p-3">
        <div className="d-flex justify-content-between align-items-start w-100">
          <div className="free-plan-section">
            <h3 className="" style={{ fontSize: "24px" }}>
              You are currently on a free plan
            </h3>
            <div>
              {/* Our Trial API keys are free to use. To serve your users with
              production-grade performance, you will need a Production
              API key and a paid plan. It's simple to upgrade — just complete a
              short application form. */}
              You're currently enjoying our free plan! We'll be rolling out paid
              plans soon to unlock even more features, but in the meantime, feel
              free to experiment and test all our models to their full
              potential.
            </div>
            {/* <div className="my-3">
              <ProgressBar
                isLabelVisible={false}
                completed={35}
                bgColor={"#8906f6"}
                baseBgColor={"#D9D9D9"}
                height={"8px"}
              />
            </div> */}
            {/* <div style={{ fontWeight: 500, fontSize: "16px" }}>
              10,000 tokens out of 100,000 are used
            </div> */}
          </div>
          {/* <div className="">
            <Button
              type="primary"
              className="aiml-primary-btn"
              icon={<img src={Sparkle} alt="" className="w-100 h-100" />}
              onClick={() => showModal()}
            >
              Upgrade Plan
            </Button>
          </div> */}
        </div>
      </div>
      <div className="plans-billing-plans-container bg-white p-3 my-3">
        <div style={{ fontWeight: 500, fontSize: "16px" }}>FAQs</div>
        <div className="my-4">
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <LeftOutlined rotate={isActive ? -90 : 0} />
            )}
            ghost
            destroyInactivePanel={true}
            items={getItems(panelStyle)}
            expandIconPosition={"end"}
          />
        </div>
      </div>
      <div>
        <Modal
          title="Upgrade your Plan"
          open={isModalVisible}
          onCancel={handleCancel}
          width={944}
          footer={null} // Remove the default OK and Cancel buttons
        >
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-between align-items-start gap-3 w-100">
              <div className="w-50 pricing-card">
                <div className="text-center my-2 upgrade-plan-heading">
                  Free of charge*
                </div>
                <div className="text-center px-4 my-3">
                  <Button className="aiml-btn">Your Current Plan</Button>
                </div>
                <div className="my-3 mx-4">
                  <div className="upgrade-plan-points">RATE LIMITS</div>
                  <div className="upgrade-plan-values">
                    15 RPM (requests per minute)
                    <br />
                    32,000 TPM (tokens per minute)
                    <br />
                    1500 RPD (requests per day)
                  </div>
                </div>
                <div className="my-3 mx-4">
                  <div className="upgrade-plan-points">PRICE (INPUT)</div>
                  <div className="upgrade-plan-values">Free of charge</div>
                </div>
                <div className="my-3 mx-4">
                  <div className="upgrade-plan-points">PRICE (OUTPUT)</div>
                  <div className="upgrade-plan-values">Free of charge</div>
                </div>
                <div className="my-3 mx-4">
                  <div className="upgrade-plan-points">
                    DATA USED TO IMPROVE OUR PRODUCT
                  </div>
                  <div className="upgrade-plan-values">Yes</div>
                </div>
              </div>
              <div className="w-50 pricing-card">
                <div className="text-center my-2 upgrade-plan-heading">
                  Pay-as-you-go
                </div>
                <div className="text-center px-4 my-3">
                  <Button
                    className="aiml-btn"
                    icon={<img src={Sparkle} alt="" className="w-100 h-100" />}
                    onClick={handleButtonClick}
                  >
                    Upgrade Plan
                  </Button>
                </div>
                <div className="my-3 mx-4">
                  <div className="upgrade-plan-points">RATE LIMITS</div>
                  <div className="upgrade-plan-values">
                    15 RPM (requests per minute)
                    <br />
                    32,000 TPM (tokens per minute)
                    <br />
                    1500 RPD (requests per day)
                  </div>
                </div>
                <div className="my-3 mx-4">
                  <div className="upgrade-plan-points">PRICE (INPUT)</div>
                  <div className="upgrade-plan-values">
                    ₹40 / 1 million tokens
                  </div>
                </div>
                <div className="my-3 mx-4">
                  <div className="upgrade-plan-points">PRICE (OUTPUT)</div>
                  <div className="upgrade-plan-values">
                    ₹120 / 1 million tokens
                  </div>
                </div>
                <div className="my-3 mx-4">
                  <div className="upgrade-plan-points">
                    DATA USED TO IMPROVE OUR PRODUCT
                  </div>
                  <div className="upgrade-plan-values">Yes</div>
                </div>
              </div>
            </div>
            <div
              className="text-center mt-4"
              style={{ fontWeight: 500, fontSize: "16px", color: "#060216" }}
            >
              Need more capabilities?{" "}
              <span style={{ fontWeight: 700 }}>See ai.ml Enterprise</span>
            </div>
          </div>
        </Modal>
      </div>
      <Modal
        title="Checkout"
        open={checkoutModalVisible}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose
        centered
      >
        <Home />
      </Modal>
    </div>
  );
};
export default PlansBilling;
