import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import * as Sentry from "@sentry/react";

const initialState = {
  textToTextModelTypes: [],
  modelType: [],
  modelName: [],
  getConversationPromptsData: [],
  showConversationData: [],
  newConversation: null,
};

export const textToTextModelNames = createAsyncThunk(
  "modelNames/textToTextModelNames",
  async ({ model_id }) => {
    try {
      const response = await axiosInstance.get(
        `${MINSKY_URL.textToTextModelNames}?model_type=${model_id}`
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export const textToTextModelTypes = createAsyncThunk(
  "modelTypes/textToTextModelTypes",
  async () => {
    try {
      const response = await axiosInstance.get(
        `${MINSKY_URL.textToTextModelTypes}`
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);

export const modelTypes = createAsyncThunk("modelType/modelTypes", async () => {
  try {
    const response = await axiosInstance.get(`${MINSKY_URL.modelTypes}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
});

export const textToSpeechLangNames = createAsyncThunk(
  "languageNames/textToSpeechLangNames",
  async () => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.textToSpeechLanguage}`)
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
);

export const speechToTextModelNames = createAsyncThunk(
  "modelNames/speechToTextModelNames",
  async () => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.speechToTextModel}`)
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
);

export const textToSpeechModelNames = createAsyncThunk(
  "modelNames/speechToTextModelNames",
  async () => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.textToSpeechModel}`)
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
);

export const promptOptions = createAsyncThunk(
  "options/promptOptions",
  async () => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.getPrompt}`)
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
);

export const createPrompt = createAsyncThunk(
  "prompt/createPrompt",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.createPrompt}`, requestBody)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const updatePrompt = createAsyncThunk(
  "prompt/updatePrompt",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.updatePrompt}`, requestBody)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const deletePrompt = createAsyncThunk(
  "prompt/deletePrompt",
  async ({ prompt }) => {
    const response = await axiosInstance
      .delete(`${MINSKY_URL.deletePrompt}?prompt=${prompt}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const getPromptConversationDataAction = createAsyncThunk(
  "promptConversation/getPromptConversationDataAction",
  async (payload) => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.promptConversationDataAPI}?limit=${payload.limit}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const showConversationDataAction = createAsyncThunk(
  "showPromptConversation/showConversationDataAction",
  async ({ payload, historyMessagecallBack }) => {
    try {
      const response = await axiosInstance.get(
        `${MINSKY_URL.promptConversationDataAPI}?conversation=${payload?.conversationId}`
      );
      if (response) {
        historyMessagecallBack && historyMessagecallBack(response.data);
        return response.data;
      } else {
        throw new Error("Failed to download bill: Invalid status code");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
);

export const createNewConversationIdAction = createAsyncThunk(
  "newConversationId/createNewConversationIdAction",
  async (handleCallBack) => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.newConversationApi}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    handleCallBack && handleCallBack(response?.data?.conversation_id);
    return response.data;
  }
);

export const updateReducerAction = createAsyncThunk(
  "updateReducer/updateReducerAction",
  async (payload) => {
    // Simulate an async operation
    return payload;
  }
);

export const clearPreviousReducer = createAsyncThunk(
  "clearReducer/clearPreviousReducer",
  async () => {
    // Simulate an async operation
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }
);

export const modelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPrompt.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(createPrompt.fulfilled, (state, action) => {})
      .addCase(createPrompt.rejected, (state, action) => {
        state.status = "failed";
      })

      //---------------------------------------------------------------------
      .addCase(updatePrompt.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(updatePrompt.fulfilled, (state, action) => {})
      .addCase(updatePrompt.rejected, (state, action) => {
        state.status = "failed";
      })

      //----------------------------------------------------------------------
      .addCase(deletePrompt.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(deletePrompt.fulfilled, (state, action) => {})
      .addCase(deletePrompt.rejected, (state, action) => {
        state.status = "failed";
      })

      //----------------------------------------------------------

      .addCase(textToTextModelTypes.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(textToTextModelTypes.fulfilled, (state, action) => {
        state.textToTextModelTypes = action.payload;
      })
      .addCase(textToSpeechModelNames.rejected, (state, action) => {
        state.status = "failed";
      })

      //----------------------------------------------------------

      .addCase(modelTypes.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(modelTypes.fulfilled, (state, action) => {
        state.modelType = action.payload;
      })
      .addCase(modelTypes.rejected, (state, action) => {
        state.status = "failed";
      })
      //----------------------------------------------------------

      .addCase(getPromptConversationDataAction.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(getPromptConversationDataAction.fulfilled, (state, action) => {
        state.getConversationPromptsData = action.payload;
      })
      .addCase(getPromptConversationDataAction.rejected, (state, action) => {
        state.status = "failed";
      })
      //----------------------------------------------------------

      .addCase(showConversationDataAction.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(showConversationDataAction.fulfilled, (state, action) => {
        state.showConversationData = action.payload;
      })
      .addCase(showConversationDataAction.rejected, (state, action) => {
        state.status = "failed";
      })
      //----------------------------------------------------------

      .addCase(createNewConversationIdAction.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(createNewConversationIdAction.fulfilled, (state, action) => {
        state.newConversation = action.payload;
      })
      .addCase(createNewConversationIdAction.rejected, (state, action) => {
        state.status = "failed";
      })

      //-------------------------------------------------------------
      .addCase(updateReducerAction.fulfilled, (state, action) => {
        state.newConversation = action.payload;
      })

      //--------------------------------------------------------------

      .addCase(clearPreviousReducer.fulfilled, (state, action) => {
        state.newConversation = null;
      });
  },
});

// export const {} = modelSlice.actions;
export const modelReducer = modelSlice.reducer;
