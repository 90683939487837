import {
  CheckCircleOutlined,
  CopyOutlined,
  EllipsisOutlined,
  LinkOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Modal, Space, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import key from "../../Assets/key.svg";
import Dots from "../../Assets/dots.svg";
import DeleteIcon from "../../Assets/deleteIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createApiKeyAction,
  deleteApiKeyAction,
  disableApiKeyAction,
  getApiKeyDataAction,
} from "../../slices/settingsSlice";
import moment from "moment/moment";
import Loader from "../../Reusable Components/Loader/Loader";
import { toast } from "react-toastify";

const ApiKeys = () => {
  const dispatch = useDispatch();
  const [openGenerateKeyModal, setOpenGenerateKeyModal] = useState(false);

  const [isKeyGenerated, setIsKeyGenerated] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [disableKey, setDisableKey] = useState(false);

  const [openReEnableKey, setOpenReEnableKey] = useState(false);

  const [apiKeyName, setAPiKeyName] = useState("");

  const [selectedRow, setSelectedRow] = useState("");

  const [keyName, setKeyName] = useState("");

  const { createApiKeyData } = useSelector(
    (state) => state.setting.createApiKey
  );

  const { getApiKeyData } = useSelector((state) => state.setting.getApiKey);

  const isGetApiKeyDataLoading = useSelector(
    (state) => state.setting.getApiKey.isGetApiKeyDataLoading
  );

  console.log("createApiKeyData", createApiKeyData);

  const handleDisableKey = () => {
    let isActive = true;
    if (disableKey === true) {
      isActive = false;

      const payload = {
        id: selectedRow,
        is_active: isActive,
      };
      dispatch(disableApiKeyAction({ payload, handleSuccessCB }));
    } else {
      setOpenReEnableKey(true);
    }
    setDropdownOpen(false);
  };

  const handleReEnableKey = () => {
    const payload = {
      id: selectedRow,
      is_active: true,
    };

    dispatch(disableApiKeyAction({ payload, handleSuccessCB }));
    setOpenReEnableKey(false);
  };

  const handleSuccessCB = () => {
    dispatch(getApiKeyDataAction());
  };

  const handleDeleteKey = () => {
    const payload = {
      id: selectedRow,
    };
    dispatch(deleteApiKeyAction({ payload, handleSuccessCB }));
    setDropdownOpen(false);
  };

  const visibleHandler = (visible, rowId, active, keyName) => {
    setDropdownOpen(visible);
    setKeyName(keyName);
    if (visible) {
      setSelectedRow(rowId);
      setDisableKey(active);
    } else {
      setSelectedRow("");
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="disableKey" onClick={handleDisableKey}>
        {disableKey === true ? (
          <StopOutlined className="" size={10} />
        ) : (
          <CheckCircleOutlined size={10} />
        )}
        <span className="mx-1">
          {disableKey === true ? "Disable API Key" : "Re-enable API Key"}
        </span>
      </Menu.Item>
      <Menu.Item key="deleteKey" onClick={handleDeleteKey}>
        <div className="d-flex justify-content-center align-items-center">
          <img src={DeleteIcon} alt="deleteIcon" className="delete-icon-img" />
          <div className="mx-2" style={{ color: "#DB1919" }}>
            Delete API Key
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const TableDataColumns = [
    {
      title: "NAME",
      dataIndex: "key_name",
      key: "key_name",
      width: 400,
      render: (title, record) => {
        return (
          <>
            <div className="d-flex justify-content-start align-items-center w-100">
              {title}

              {record.is_active === false ? (
                <div className="mx-3" style={{ color: "rgba(6, 2, 22, 0.49)" }}>
                  <LinkOutlined className="" /> Disabled
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      title: "KEY",
      dataIndex: "api_key",
      key: "api_key",
    },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      key: "CreatedAt",
      width: 400,
      render: (_, record) =>
        moment(record?.created_at).format("DD/MM/YYYY, hh:mm:ss"),
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, record) =>
        moment(record?.updated_at).format("DD/MM/YYYY, hh:mm:ss"),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <div
            className="dropDown d-flex justify-content-end align-items-center"
            onClick={(e) => e.preventDefault()}
          >
            <Space>
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                onVisibleChange={(visible) =>
                  visibleHandler(
                    visible,
                    record.id,
                    record?.is_active,
                    record?.key_name
                  )
                }
              >
                <div className="dropDown">
                  <button type="button" className="drop-btn">
                    {dropdownOpen ? (
                      record ? (
                        <EllipsisOutlined />
                      ) : (
                        <img src={Dots} alt="code" />
                      )
                    ) : (
                      <EllipsisOutlined />
                    )}
                  </button>
                </div>
              </Dropdown>
            </Space>
          </div>
        );
      },
    },
  ];

  const showModalGenerateKey = () => {
    setOpenGenerateKeyModal(true);
  };

  const handleChange = (e) => {
    setAPiKeyName(e.target.value);
  };

  const handleOkGenerateKey = () => {
    const payload = {
      key_name: apiKeyName,
    };
    dispatch(createApiKeyAction(payload));
    setIsKeyGenerated(true);
    setOpenGenerateKeyModal(false);
  };

  const handleClose = () => {
    setIsKeyGenerated(false);
    setAPiKeyName("");
    toast.success("key registered successfully");
    dispatch(getApiKeyDataAction());
  };

  const handleCancelGenerateKey = () => {
    setAPiKeyName("");
    setOpenGenerateKeyModal(false);
  };

  useEffect(() => {
    dispatch(getApiKeyDataAction());
  }, [dispatch]);

  return (
    <div className="aiml-card your-organisation">
      <div className="ailm-card-header">
        <div className="d-flex justify-content-start align-items-center">
          <h3 className="card-title">
            {getApiKeyData?.data?.length > 0 ? "API keys" : "Get API keys"}
          </h3>
        </div>
        <Button
          type="primary"
          className="aiml-primary-btn"
          icon={<img src={key} alt="" className="w-100 h-100" />}
          onClick={showModalGenerateKey}
        >
          Create Key
        </Button>
      </div>
      <div className="aiml-card-body">
        {!isGetApiKeyDataLoading ? (
          getApiKeyData?.data?.length > 0 ? (
            <div>
              <Table
                dataSource={getApiKeyData.data}
                columns={TableDataColumns}
                pagination={false}
                className="aiml-table"
              />
            </div>
          ) : (
            <div className="">
              Generate a key to start integrating with the AI.ML API directly or
              using a <u role="button">client SDK.</u>
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 180,
            }}
          >
            <Loader />
          </div>
        )}
      </div>
      <Modal
        open={openGenerateKeyModal}
        title="Create API key"
        width={415}
        wrapClassName="aiml-modal-dialog"
        onCancel={handleCancelGenerateKey}
        onOk={handleOkGenerateKey}
        okText="Create Key"
      >
        <div className="fInput-group my-3">
          <label className="input-label">Name your key</label>
          <Input
            placeholder="harsh-secret-key"
            className="form-input"
            value={apiKeyName}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </Modal>
      <Modal
        open={isKeyGenerated}
        title="Key successfully added!"
        width={415}
        wrapClassName="aiml-modal-dialog"
        onCancel={() => setIsKeyGenerated(false)}
        footer={(_) => (
          <div className="" style={{ width: "100%" }}>
            <Button className="w-100 ant-btn-cancel" onClick={handleClose}>
              Close
            </Button>
          </div>
        )}
      >
        <div className="fInput-group my-3">
          <label className="input-label">
            You must keep a record of the key below as you will not be able to
            view it again.
          </label>
          <div className="position-relative">
            <TextArea
              rows={6}
              maxLength={200}
              autoSize={{
                minRows: 6,
                maxRows: 6,
              }}
              placeholder="harsh-secret-key"
              className=""
              size="middle"
              value={createApiKeyData?.api_key}
              disabled
            />
            <div
              className="position-absolute copy-key-position"
              onClick={() => {
                navigator.clipboard.writeText(createApiKeyData?.api_key);
                toast.success("Key Copied");
              }}
              role="button"
            >
              <CopyOutlined className="mx-1" />
              Copy Key
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openReEnableKey}
        title="Re-enable key?"
        width={415}
        wrapClassName="aiml-modal-dialog"
        onCancel={() => setOpenReEnableKey(false)}
        footer={(_) => (
          <div
            className="d-flex justify-content-between align-items-center gap-2"
            style={{ width: "100%" }}
          >
            <Button
              className="w-50 ant-btn-cancel"
              onClick={() => setOpenReEnableKey(false)}
            >
              Cancel
            </Button>
            <Button
              className="w-50 ant-button-create-key"
              onClick={handleReEnableKey}
              color="#285FF7"
            >
              Re-enable
            </Button>
          </div>
        )}
      >
        <div className="my-3" style={{ color: "rgba(6, 2, 22, 0.52)" }}>
          Are you sure you'd like to re-enable {keyName}?
        </div>
      </Modal>
    </div>
  );
};

export default ApiKeys;
