import React, { useState, useEffect, useContext } from "react";
import { Select, Input, Slider, Collapse } from "antd";
import Accordianbutton from "../Assets/Accordian.svg";
import { ModelContext } from "../ContextApis/ModelContext";
// import { LoginContext } from "../ContextApis/LoginContext";
import { useDispatch, useSelector } from "react-redux";
import "./TextModification.css";
import infoImage from "../Assets/info.svg";
import downArrow from "../Assets/downArrow.svg";
import {
  clearPreviousReducer,
  getPromptConversationDataAction,
  showConversationDataAction,
} from "../slices/modelSlice";
import moment from "moment/moment";
import "moment-timezone";

const { TextArea } = Input;
// const { Option } = Select;
const { Panel } = Collapse;

export const TextModification = () => {
  const dispatch = useDispatch();

  const [activePanel, setActivePanel] = useState("panel1");

  const handlePanelChange = (key) => {
    if (activePanel === key[0]) {
      setActivePanel(null);
    } else {
      if (activePanel) {
        setActivePanel(null);
      }
      setActivePanel(key[0]);
    }
  };

  const {
    modelName = [],
    setselectedModelName,
    selectedModelName,
    setOutputLengthRange,
    outputLengthRange,
    setTemperatureRange,
    temperatureRange,
    topPRange,
    setTopPRange,
    topKRange,
    setTopKRange,
    repetitionPenaltyRange,
    setRepetitionPenaltyRange,
    maxSeq,
    setMaxSeq,

    // setSystemPrompt,
    // systemPrompt,
    selectedLanguage,
    setselectedLanguage,
    setSysInstructionsPrompt,
    sysInstructionPrompt,
    cardName,
    toggleModalSetting,
    setToggleModalSetting,
    setMessages,
    setConversationHistoryId,
    // previousModelName,
    setPreviousModelName,
    eventSourceRef,
  } = useContext(ModelContext);
  // const { AuthData } = useContext(LoginContext);
  const [editableOutputLength, setEditableOutputLength] = useState(
    outputLengthRange[0]
  );
  const [editTempInput, seteditTempInput] = useState(temperatureRange[0]);
  const [editTopPInput, seteditTopPInput] = useState(topPRange[0]);
  const [editTopKInput, seteditTopKInput] = useState(topKRange[0]);
  const [editRepetitionInput, seteditRepetitionInput] = useState(
    repetitionPenaltyRange[0]
  );

  const { getConversationPromptsData } = useSelector((state) => state.model);

  console.log("getConvodata", getConversationPromptsData);

  useEffect(() => {
    setOutputLengthRange([512, maxSeq]);
    setEditableOutputLength(512);
  }, [maxSeq, setOutputLengthRange, setEditableOutputLength]);

  const modelOptions = (modelName || []).map((name) => ({
    value: name?.model_id,
    label: name?.model_name,
    max_seq: name?.max_seq,
    // max_seq:
  }));
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cardName && modelName) {
      const selectedModel = modelName?.find(
        (model) => model?.model_name === cardName
      );
      console.log("sdss", modelName, selectedModel, cardName);
      setselectedModelName(selectedModel);
      setPreviousModelName(selectedModel);
    }
  }, [cardName, modelName, setselectedModelName]);
  /* eslint-enable react-hooks/exhaustive-deps */
  console.log("sdss11", modelName, cardName);

  const handleModelChange = (value) => {
    setPreviousModelName(selectedModelName);
    const selectedModel = modelName?.find((model) => model?.model_id === value);
    setselectedModelName(selectedModel);

    // setselectedModelName(selectedModel);
    setOutputLengthRange([512, maxSeq]);
    setEditableOutputLength(512);
    setSysInstructionsPrompt("");
    dispatch(clearPreviousReducer());
    setConversationHistoryId("");
    const payload = {
      limit: 5,
    };
    dispatch(getPromptConversationDataAction(payload));
  };

  useEffect(() => {
    const selectedModel = modelName.find(
      (model) => model?.model_id === selectedModelName
    );
    // console.log(selectedModel)
    setMaxSeq(selectedModel ? selectedModel?.max_seq : null);
  }, [selectedModelName, modelName, setMaxSeq]);

  useEffect(() => {
    const selectedModel = modelOptions?.find(
      (model) => model?.value === selectedModelName?.model_id
    );
    setMaxSeq(selectedModel ? selectedModel.max_seq : null);
  }, [selectedModelName, modelName, setMaxSeq, modelOptions]);

  const handleOutputLengthChange = (value) => {
    const clampedRange = Math.min(Math.max(value, 0), maxSeq);

    setEditableOutputLength(clampedRange);

    setOutputLengthRange([clampedRange, outputLengthRange[1]]);
  };

  const handleOutputEditableInputChange = (e) => {
    const inputValue = parseInt(e.target.value, 10) || 0;
    const clampedValue = Math.min(Math.max(inputValue, 0), maxSeq);
    setEditableOutputLength(clampedValue);
    setOutputLengthRange([clampedValue, outputLengthRange[1]]);
  };

  const handleTemperatureChange = (newRange) => {
    const clampedRange = Math.min(Math.max(newRange, 0), 2);
    setTemperatureRange([clampedRange, temperatureRange[1]]);
    seteditTempInput(clampedRange);
  };

  const handleTempEditableInputChange = (e) => {
    const inputValue = parseFloat(e.target.value);
    if (!isNaN(inputValue)) {
      const clampedValue = Math.min(Math.max(inputValue, 0), 2);
      seteditTempInput(clampedValue);
      setTemperatureRange([clampedValue, maxSeq]);
    }
  };

  const handleTopPChange = (newRange) => {
    const clampedRange = Math.min(Math.max(newRange, 0), 2);
    setTopPRange([clampedRange, topPRange[1]]);
    seteditTopPInput(clampedRange);
  };

  const handleTopPEditableInputChange = (e) => {
    const inputValue = parseFloat(e.target.value);
    if (!isNaN(inputValue)) {
      const clampedValue = Math.min(Math.max(inputValue, 0), 2);
      seteditTopPInput(clampedValue);
      setTopPRange([clampedValue, maxSeq]);
    }
  };

  const handleTopKChange = (newRange) => {
    const clampedRange = Math.min(Math.max(newRange, 0), 100);
    setTopKRange([clampedRange, topKRange[1]]);
    seteditTopKInput(clampedRange);
  };

  const handleTopKEditableInputChange = (e) => {
    const inputValue = parseInt(e.target.value, 10) || 0;
    const clampedValue = Math.min(Math.max(inputValue, 0), 100);
    seteditTopKInput(clampedValue);
    setTopKRange([clampedValue, maxSeq]);
  };

  const handleRepetitionPenaltyChange = (newRange) => {
    const clampedRange = Math.min(Math.max(newRange, 0), 2);
    setRepetitionPenaltyRange([clampedRange, repetitionPenaltyRange[1]]);
    seteditRepetitionInput(clampedRange);
  };

  const handleRepetitionEditableInputChange = (e) => {
    const inputValue = parseFloat(e.target.value);
    if (!isNaN(inputValue)) {
      const clampedValue = Math.min(Math.max(inputValue, 0), 2);
      seteditRepetitionInput(clampedValue);
      setRepetitionPenaltyRange([clampedValue, maxSeq]);
    }
  };

  const handleSystemInsChanges = (e) => {
    setSysInstructionsPrompt(e.target.value);
  };
  const handleModalSettingToggle = () => {
    setToggleModalSetting(!toggleModalSetting);
  };

  const historyMessagecallBack = (res) => {
    const messages = res.map((item) => ({
      id: item.message_id,
      text: item.text,
      isBot: item.message_type === "AA" ? true : false,
    }));
    setMessages(messages);
  };

  const handlePromptClick = (id) => {
    const payload = {
      conversationId: id,
    };
    setConversationHistoryId(id);
    eventSourceRef?.current?.close();
    dispatch(showConversationDataAction({ payload, historyMessagecallBack }));
  };
  useEffect(() => {}, [selectedLanguage, setselectedLanguage]);

  useEffect(() => {
    const payload = {
      limit: 5,
    };
    dispatch(getPromptConversationDataAction(payload));
  }, [dispatch]);
  return (
    <div className="model-setting">
      <div className="model-setting-header">
        <h4 className="model-heading">Modal Setting</h4>
        <img
          src={Accordianbutton}
          alt="ai.ml"
          width={20}
          height={20}
          onClick={handleModalSettingToggle}
          className="accordian-image"
        />
      </div>
      <div className="modal-setting-body">
        <div className="model-setting-innerBody">
          <label
            htmlFor="model-select--header"
            className="model-selection-subheader"
          >
            Model
            <img src={infoImage} alt="ai.ml" />
          </label>
          <div className="fInput-group">
            <Select
              id="modelSelect"
              placeholder="Select a Model"
              optionFilterProp="children"
              options={modelOptions}
              value={selectedModelName?.model_id}
              onChange={handleModelChange}
              className="form-input"
            ></Select>
          </div>
          <div className="system-instructions">
            <label
              htmlFor="system-instructions"
              className="system-instructions-label"
            >
              System Instructions
              <img src={infoImage} alt="ai.ml" className="image-after-label" />
            </label>
            <TextArea
              id="system-instructions"
              className="system-instructions-textarea"
              // disabled
              value={
                sysInstructionPrompt
                  ? sysInstructionPrompt
                  : selectedModelName?.start_prompt
              }
              onChange={(e) => handleSystemInsChanges(e)}
            />
          </div>

          <div className="modification-container">
            <div>
              <Collapse
                defaultActiveKey={[]}
                accordion
                className="collapse-model-modifications"
                onChange={handlePanelChange}
              >
                <Panel
                  header={
                    <div className="panel-model-modifications">
                      <p className="parameters-title">
                        Advanced Parameters &nbsp;
                        <img
                          src={downArrow}
                          alt="ai.ml"
                          className="image-after-label"
                          // onClick={(e) => e.stopPropagation()}
                        />
                      </p>
                    </div>
                  }
                  key="panel2"
                  showArrow={false}
                  collapsible={false}
                  collapsed={activePanel !== "panel2"}
                >
                  <div className="main-parameters">
                    <div style={{ width: "100%", paddingBottom: "20px" }}>
                      <div className="parameters-container">
                        <span>Output Length</span>
                        <span className="parameter-text-edit">
                          <Input
                            value={editableOutputLength}
                            onChange={handleOutputEditableInputChange}
                            className="parameter-edit-field"
                          />
                        </span>
                      </div>
                      <div>
                        <Slider
                          min={0}
                          max={maxSeq ? maxSeq : 600}
                          step={1}
                          value={outputLengthRange}
                          onChange={handleOutputLengthChange}
                        />
                      </div>

                      <div className="parameters-container">
                        <span>Temperature</span>
                        <span className="parameter-text-edit">
                          <Input
                            value={editTempInput}
                            onChange={handleTempEditableInputChange}
                            className="parameter-edit-field"
                          />
                        </span>
                      </div>
                      <div>
                        <Slider
                          min={0}
                          max={2}
                          step={0.1}
                          value={temperatureRange}
                          onChange={handleTemperatureChange}
                        />
                      </div>
                      <div className="parameters-container">
                        <span>Top-P</span>
                        <span className="parameter-text-edit">
                          <Input
                            value={editTopPInput}
                            onChange={handleTopPEditableInputChange}
                            className="parameter-edit-field"
                          />
                        </span>
                      </div>
                      <div>
                        <Slider
                          min={0}
                          max={2}
                          step={0.1}
                          value={topPRange}
                          onChange={handleTopPChange}
                        />
                      </div>
                      <div className="parameters-container">
                        <span>Top-K</span>
                        <span className="parameter-text-edit">
                          <Input
                            value={editTopKInput}
                            onChange={handleTopKEditableInputChange}
                            className="parameter-edit-field"
                          />
                        </span>
                      </div>
                      <div>
                        <Slider
                          min={0}
                          max={100}
                          step={1}
                          value={topKRange}
                          onChange={handleTopKChange}
                        />
                      </div>
                      <div className="parameters-container">
                        <span>Repetition Penalty</span>
                        <span className="parameter-text-edit">
                          <Input
                            value={editRepetitionInput}
                            onChange={handleRepetitionEditableInputChange}
                            className="parameter-edit-field"
                          />
                        </span>
                      </div>
                      <div>
                        <Slider
                          min={0}
                          max={2}
                          step={0.1}
                          value={repetitionPenaltyRange}
                          onChange={handleRepetitionPenaltyChange}
                        />
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>

            <div className="system-prompt-dropdown">
              <label
                htmlFor="system-instructions"
                className="system-prompt-title"
              >
                Your Prompts &nbsp;
                <img
                  src={infoImage}
                  alt="ai.ml"
                  className="image-after-label"
                />
              </label>
              <div className="prompt-box">
                <ul>
                  {getConversationPromptsData?.map((items) => (
                    <li
                      className="prompt-item"
                      key={items?.conversation_id}
                      onClick={() => handlePromptClick(items?.conversation_id)}
                    >
                      <h4 className="item-title">{items?.title}</h4>
                      <p className="item-text">
                        {moment(items.created_at + "Z")
                          .tz("Asia/Kolkata")
                          .format("D MMMM YYYY, HH:mm")}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
