import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import * as Sentry from "@sentry/react";

const initialState = {
  texttospeech: {
    isLoading: false,
  },

  chatToText: {
    isLoading: false,
  },
};

export const textToSpeech = createAsyncThunk(
  "speech/textToSpeech",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.textToSpeech}`, requestBody)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const textTotextStreaming = createAsyncThunk(
  "speech/textTotextStreaming",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.textToTextStreaming}`, requestBody)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const textTotext = createAsyncThunk(
  "speech/textTotext",
  async ({ requestBody }) => {
    try {
      const response = await axiosInstance.post(
        `${MINSKY_URL.textToText}`,
        requestBody
      );
      if (response.status === 500) {
        Sentry.captureException("Server Error");
      }
      return response.data;
    } catch (error) {
      console.error("Error in textTotext thunk:", error);

      if (error.response) {
        // The request was made and the server responded with a status code
        console.error("Status code:", error.response.status);
        console.error("Error response data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
      }
      if (error.response.status === 500) {
        Sentry.captureException("Server Error");
      }

      return error.response.data;
    }
  }
);

//--------------------- For Speech To Text : Sending the Audio --------------------------
export const sendingAudioSpeechToText = createAsyncThunk(
  "speech/sendingAudioSpeechToText",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.speechToText}`, requestBody)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

//--------------------- For Speech To Text : Custom Audio (Dragged from PC) --------------------------
export const customAudioSpeechToText = createAsyncThunk(
  "speech/customAudioSpeechToText",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.speechToText}`, requestBody)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const speechSlice = createSlice({
  name: "speech",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(textToSpeech.pending, (state, action) => {
        state.status = "pending";
        state.texttospeech.isLoading = true;
      })
      .addCase(textToSpeech.fulfilled, (state, action) => {
        state.texttospeech.isLoading = false;
      })
      .addCase(textToSpeech.rejected, (state, action) => {
        state.status = "failed";
        state.isLoading = false;
      })

      //-----------------------------------------------
      .addCase(textTotext.pending, (state, action) => {
        state.status = "pending";
        state.chatToText.isLoading = true;
      })
      .addCase(textTotext.fulfilled, (state, action) => {
        state.chatToText.isLoading = false;
      })
      .addCase(textTotext.rejected, (state, action) => {
        state.status = "failed";
        state.chatToText.isLoading = false;
      })

      //-----------------------------------------------

      .addCase(textTotextStreaming.pending, (state, action) => {
        state.status = "pending";
        state.chatToText.isLoading = true;
      })
      .addCase(textTotextStreaming.fulfilled, (state, action) => {
        state.chatToText.isLoading = false;
      })
      .addCase(textTotextStreaming.rejected, (state, action) => {
        state.status = "failed";
        state.chatToText.isLoading = false;
      })

      //-----------------------------------------------
      .addCase(customAudioSpeechToText.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(customAudioSpeechToText.fulfilled, (state, action) => {})
      .addCase(customAudioSpeechToText.rejected, (state, action) => {
        state.status = "failed";
      })

      //-----------------------------------------------
      .addCase(sendingAudioSpeechToText.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(sendingAudioSpeechToText.fulfilled, (state, action) => {})
      .addCase(sendingAudioSpeechToText.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

// export const {} = speechSlice.actions;
export const speechReducer = speechSlice.reducer;
