import React, { useEffect, useState } from "react";
import { Input, Button } from "antd";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrgDataAction,
  yourOrganizationAction,
} from "../../slices/settingsSlice";
import Loader from "../../Reusable Components/Loader/Loader";

const YourOrganisation = () => {
  const dispatch = useDispatch();
  const [orgName, setOrgName] = useState("");
  const [primaryAddress, setprimaryAddress] = useState("");
  const [primaryAddress2, setprimaryAddress2] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const getYourOrgData = useSelector(
    (state) => state.setting.getOrgData.getYourOrgData
  );

  const isOrgDataLoading = useSelector(
    (state) => state.setting.getOrgData.isOrgDataLoading
  );

  const orgDataCheck = useSelector(
    (state) => state.setting.orgCheckData.orgDataCheck
  );

  const handleChange = (e, name) => {
    if (name === "orgName") {
      setOrgName(e.target.value);
    } else if (name === "primaryAddress") {
      setprimaryAddress(e.target.value);
    } else if (name === "primaryAddress2") {
      setprimaryAddress2(e.target.value);
    } else if (name === "country") {
      setCountry(e.target.value);
    } else if (name === "state") {
      setState(e.target.value);
    } else if (name === "city") {
      setCity(e.target.value);
    } else if (name === "postalCode") {
      setPostalCode(e.target.value);
    }
  };

  // const handleCountryChange = (value) => {
  //   setCountry(value);
  // };

  const handleCallback = () => {
    dispatch(getOrgDataAction());
  };

  const handleSubmit = () => {
    const payload = {
      name: orgName,
      // group_description:,
      primary_address: primaryAddress,
      secondary_address: primaryAddress2,
      country: country,
      state: state,
      city: city,
      pincode: postalCode,
      // group_description: "this group is for",
    };
    dispatch(yourOrganizationAction({ payload, handleCallback }));
  };

  const handleClearData = () => {
    setOrgName("");
    setprimaryAddress("");
    setprimaryAddress2("");
    setCountry("");
    setState("");
    setCity("");
    setPostalCode("");
  };

  useEffect(() => {
    dispatch(getOrgDataAction());
  }, [dispatch]);

  useEffect(() => {
    if (orgDataCheck && orgDataCheck?.is_assigned === true) {
      setOrgName(orgDataCheck?.group_detail?.name);
      setprimaryAddress(
        orgDataCheck?.group_detail?.attributes?.primary_address?.[0]
      );
      setprimaryAddress2(
        orgDataCheck?.group_detail?.attributes?.primary_address2?.[0]
      );
      setCountry(orgDataCheck?.group_detail?.attributes?.country?.[0]);
      setState(orgDataCheck?.group_detail?.attributes?.state?.[0]);
      setCity(orgDataCheck?.group_detail?.attributes?.city?.[0]);
      setPostalCode(orgDataCheck?.group_detail?.attributes?.pincode?.[0]);
    } else if (getYourOrgData && getYourOrgData?.data) {
      setOrgName(getYourOrgData?.data?.name);
      setprimaryAddress(getYourOrgData?.data?.attributes?.primary_address?.[0]);
      setprimaryAddress2(
        getYourOrgData?.data?.attributes?.primary_address2?.[0]
      );
      setCountry(getYourOrgData?.data?.attributes?.country?.[0]);
      setState(getYourOrgData?.data?.attributes?.state?.[0]);
      setCity(getYourOrgData?.data?.attributes?.city?.[0]);
      setPostalCode(getYourOrgData?.data?.attributes?.pincode?.[0]);
    }
  }, [getYourOrgData, orgDataCheck]);

  return (
    <>
      {!isOrgDataLoading ? (
        <div className="aiml-card your-organisation">
          <div className="ailm-card-header">
            <h3 className="card-title">Your organization</h3>
          </div>
          <div className="aiml-card-body">
            <form>
              <div className="row">
                <div className="col-md-3 fInput-group">
                  <label className="input-label">Org Name</label>
                  <Input
                    placeholder="Enter Org Name"
                    className="form-input"
                    value={orgName}
                    disabled={
                      getYourOrgData?.data || orgDataCheck?.is_assigned === true
                    }
                    onChange={(e) => handleChange(e, "orgName")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 fInput-group">
                  <label className="input-label">
                    Primary business address
                  </label>
                  <Input
                    placeholder="Enter business address"
                    className="form-input"
                    value={primaryAddress}
                    disabled={
                      getYourOrgData?.data || orgDataCheck?.is_assigned === true
                    }
                    onChange={(e) => handleChange(e, "primaryAddress")}
                  />
                </div>
                <div className="col-md-3 fInput-group flex items-end">
                  <label className="input-label"></label>
                  <Input
                    placeholder="Enter business address 2"
                    className="form-input"
                    value={primaryAddress2}
                    disabled={
                      getYourOrgData?.data || orgDataCheck?.is_assigned === true
                    }
                    onChange={(e) => handleChange(e, "primaryAddress2")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 fInput-group">
                  <label className="input-label">Country</label>
                  <Input
                    placeholder="Enter Country"
                    className="form-input"
                    value={country}
                    disabled={
                      getYourOrgData?.data || orgDataCheck?.is_assigned === true
                    }
                    onChange={(e) => handleChange(e, "country")}
                  />
                </div>
                <div className="col-md-3 fInput-group">
                  <label className="input-label">State or Province</label>
                  <Input
                    placeholder="Enter state"
                    className="form-input"
                    value={state}
                    disabled={
                      getYourOrgData?.data || orgDataCheck?.is_assigned === true
                    }
                    onChange={(e) => handleChange(e, "state")}
                  />
                </div>
                <div className="col-md-3 fInput-group">
                  <label className="input-label">City</label>
                  <Input
                    placeholder="Enter City"
                    className="form-input"
                    value={city}
                    disabled={
                      getYourOrgData?.data || orgDataCheck?.is_assigned === true
                    }
                    onChange={(e) => handleChange(e, "city")}
                  />
                </div>
                <div className="col-md-3 fInput-group">
                  <label className="input-label">Postal Code</label>
                  <Input
                    type="number"
                    placeholder="Enter Postal Code"
                    className="form-input"
                    value={postalCode}
                    disabled={
                      getYourOrgData?.data || orgDataCheck?.is_assigned === true
                    }
                    onChange={(e) => handleChange(e, "postalCode")}
                  />
                </div>
              </div>
            </form>
          </div>
          {getYourOrgData?.data || orgDataCheck?.is_assigned === true ? null : (
            <div className="card-footer">
              <Button
                type="primary"
                className="aiml-primary-btn"
                onClick={handleSubmit}
              >
                Save changes
              </Button>
              <Button className="aiml-btn" onClick={handleClearData}>
                Cancel
              </Button>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default YourOrganisation;
