import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import * as Sentry from "@sentry/react";

const user = JSON.parse(sessionStorage.getItem("user"));

const initialState = {
  login: {
    message: "",
    status: "",
    is_authenticated: user?.is_authenticated ? user.is_authenticated : "",
    isLoading: false,
    data: {
      user_id: user?.user_id ? user.user_id : "",
      mobile_number: user?.mobile_number ? user.mobile_number : "",
      email: user?.email ? user.email : "",
      first_name: user?.first_name ? user.first_name : "",
      last_name: user?.last_name ? user.last_name : "",
      api_key: user?.api_key ? user.api_key : "",
    },
  },

  register: {
    registerResponse: {
      status: false,
      is_authenticated: false,
      message: "",
      email: "",
      mobile_number: "",
    },
  },
  keyCloak: {
    keyCloakdata: "",
  },
};

export const login = createAsyncThunk(
  "authLogin/login",
  async (requestParam) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.login}`, requestParam)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 400) {
      // console.log(response)
      return Promise.reject(response.data.detail); // Reject with the error response data
    }
    if (response.status === 401) {
      return Promise.reject(response.data.detail);
    }
    if (response.status === 404) {
      return Promise.reject(response.data.detail);
    }

    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const register = createAsyncThunk(
  "RegisterUser/register",
  async (requestParam) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.register}`, requestParam)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const forgetPassword = createAsyncThunk(
  "forget/forgetPassword",
  async (data) => {
    const requestParam = data.user;
    const requestParam1 = data.lname;
    const response = await axiosInstance
      .get(
        `${MINSKY_URL.forgetPassword}/?user=${requestParam}&lname=${requestParam1}`,
        {
          headers: {},
        }
      )
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const keyCloakAction = (data) => {
  return {
    type: "keyCloak/keyCloakAction",
    payload: data,
  };
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = "pending";
        state.login.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login = action?.payload;
        const userData = {
          ...action.payload.data,
          is_authenticated: action.payload.is_authenticated,
        };
        state.login = action?.payload;
        state.login.isLoading = false;
        sessionStorage.setItem("user", JSON.stringify(userData));
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.login.isLoading = false;
      })

      //-------------------------------------------------

      .addCase(register.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.register.registerResponse = action?.payload;
        // console.log("Register Payload :", action?.payload);
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "failed";
      })

      //----------------------------------------------------------------

      .addCase(forgetPassword.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.register.registerResponse = action?.payload;
        // console.log("Register Payload :", action?.payload);
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.status = "failed";
      })

      //--------------------------------------------------------------

      .addMatcher(
        (action) => action.type === "keyCloak/keyCloakAction",
        (state, action) => {
          state.keyCloak.keyCloakdata = action.payload;
        }
      );
  },
});

// export const { } = authSlice.actions;
export const authReducer = authSlice.reducer;
