import React from "react";
import { Route, Routes } from "react-router-dom";
import { ChatToText } from "../ChatToText";
// import { SpeechToText } from '../SpeechToText';
// import { TexttoSpeech } from '../TexttoSpeech';
// import KnowledgeBase from '../Components/KnowledgeBase/KnowledgeBase';
// import KnowledgeSplashScreen from '../Components/KnowledgeBase/KnowledgeSplashScreen'
import "./PlaygroundRouters.css";

export const PlaygroundRouters = () => {
  // const { isAuthenticated } = useContext(LoginContext);
  return (
    <div className="authenticated">
      {/* {isAuthenticated ? ( */}
      <Routes>
        {/* <Route path="chat" element={<ChatToText />}></Route> */}
        <Route path="/:model_type" element={<ChatToText />} />

        {/* <Route path="transcribe" element={<SpeechToText />}></Route>
                    <Route path="voice" element={<TexttoSpeech />}></Route>
                    <Route path="knowledgestart" element={<KnowledgeSplashScreen />}></Route>
                    <Route path="knowledge" element={<KnowledgeBase/>}></Route> */}
        {/* <Route path="/*" element={<Navigate to="/playground/chat" />} /> */}
      </Routes>
      {/* ) : (
        <></>
      )} */}
    </div>
  );
};
