import React, { useState } from "react";
import { Button } from "antd";
// import { StarFilled } from "@ant-design/icons";
// import Edit from "../../Assets/edit.svg";
// import Rocket from "../../Assets/rocket.svg";

const ExploreCard = ({
  title,
  isTitleTag,
  text,
  features,
  assetPath,
  labelText,
  handleModelCardClick,
  id,
}) => {
  const [show, setShow] = useState(false);
  const handleShowText = (tabId) => {
    if (tabId === 1) {
      setShow(!show);
    } else if (tabId === 2) {
      setShow(!show);
    } else if (tabId === 3) {
      setShow(!show);
    } else if (tabId === 4) {
      setShow(!show);
    }
  };

  console.log("show", show, id, title);
  return (
    <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
      <div className="aiml-card explore-card">
        <div className="ailm-card-header ex-header">
          <h4 className="card-title">
            {title}
            {isTitleTag && <span className="title-tag">{labelText}</span>}
          </h4>
        </div>
        <div className="aiml-card-body">
          <p className="card-text">
            {show ? text : `${text.substring(0, 65)}...`}
            <button
              type="button"
              className="showMoreBtn"
              onClick={() => handleShowText(id)}
            >
              {show ? "Show less" : "Show more"}
            </button>
          </p>
          <Button
            type="primary"
            className="aiml-btn dash-btn"
            onClick={() => handleModelCardClick(title)}
          >
            Try Now
          </Button>
          <div className="tags-box">
            {features?.map((item) => (
              <p className="tags">
                {item?.icon && (
                  <img src={`${assetPath}${item?.icon}`} alt="edit" />
                )}{" "}
                {item?.text}
              </p>
            ))}
          </div>
        </div>
        <div className="aiml-card-footer">
          <div className="scroll-text-box">
            <p className="scroll-text">
              {title} | {title}
            </p>
            <p className="scroll-text">
              {title} | {title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreCard;
