import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {
  partitioninfo: {
    partition_info: "",
    isLoading: false,
    error: null,
  },

  addFolderinfo: {
    isLoading: false,
  },

  folderRecords: {
    loading: false,
    fileRecords: [],
  },
};

export const getFoldersInfo = createAsyncThunk(
  "folders/foldersInfo",
  async ({ rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${MINSKY_URL.getFoldersInfo}`);

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return rejectWithValue(error.response.data.detail);
      } else {
        throw error;
      }
    }
  }
);

export const addFolder = createAsyncThunk(
  "update/addFolder",
  async ({ partition }) => {
    try {
      const response = await axiosInstance.post(
        `${MINSKY_URL.addFolderVectorDB}/?partition=${partition}`,
        {}
      );
      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);
export const fetchFolderRecords = createAsyncThunk(
  "records/fetchRecords",
  async ({ partition, status, page }) => {
    const response = await axiosInstance
      .get(
        `${MINSKY_URL.getFileRecords}/?partition=${partition}&status=${status}&page=${page}&from_date&to_date`
      )
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const uploadUrlVectorDB = createAsyncThunk(
  "vectorUploadUrl/uploadUrlVectorDB",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.uploadURLs}`, requestBody)
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
);

export const uploadFileVectorDB = createAsyncThunk(
  "vectorUploadFile/uploadFileVectorDB",
  async ({ formData, file_type }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.uploadFile}/${file_type}/`, formData)
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const deleteSingleFile = createAsyncThunk(
  "deleteSingle/deleteSingleFile",
  async ({ requestBody }) => {
    const response = await axiosInstance
      .delete(`${MINSKY_URL.deleteSingleFile}`, { data: requestBody })
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
);

export const knowledgeSlice = createSlice({
  name: "knowledge",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoldersInfo.pending, (state, action) => {
        state.status = "pending";
        state.partitioninfo.isLoading = true;
      })
      .addCase(getFoldersInfo.fulfilled, (state, action) => {
        state.partitioninfo.partition_info = action.payload;
        state.partitioninfo.error = null; // Resettig  error when successful
        state.partitioninfo.isLoading = false;
      })
      .addCase(getFoldersInfo.rejected, (state, action) => {
        state.status = "failed";
        state.partitioninfo.isLoading = false;
        state.partitioninfo.error = action.payload;
      })

      //-----------------------------------------------------------------

      .addCase(addFolder.pending, (state, action) => {
        state.status = "pending";
        state.addFolderinfo.isLoading = true;
      })
      .addCase(addFolder.fulfilled, (state, action) => {
        state.addFolderinfo.isLoading = false;
      })
      .addCase(addFolder.rejected, (state, action) => {
        state.status = "failed";
        state.addFolderinfo.isLoading = false;
      })

      //-------------------------------------------------------------------------
      .addCase(fetchFolderRecords.pending, (state, action) => {
        state.status = "pending";
        state.folderRecords.loading = true;
      })
      .addCase(fetchFolderRecords.fulfilled, (state, action) => {
        state.folderRecords.fileRecords = action.payload;
        state.folderRecords.loading = false;
      })
      .addCase(fetchFolderRecords.rejected, (state, action) => {
        state.status = "failed";
        state.folderRecords.loading = false;
      });
  },
});

// export const {} = generateKeySlice.actions;
export const knowledgeReducer = knowledgeSlice.reducer;
