import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  memberInvitee: {
    isMemberInviteLoading: false,
    memberInviteData: [],
  },

  yourOrg: {
    isOrgDataLoading: false,
    orgData: [],
  },

  getOrgData: {
    isOrgDataLoading: false,
    getYourOrgData: [],
  },

  getMemberData: {
    isMemberDataLoading: false,
    memberData: [],
  },

  createApiKey: {
    isCreateApiKeyLoading: false,
    createApiKeyData: "",
  },

  getApiKey: {
    isGetApiKeyDataLoading: false,
    getApiKeyData: [],
  },

  deleteApikey: {
    isApiKeyDeletedLoading: false,
    apiKeyDelete: "",
  },

  disableApiKey: {
    isApiKeydisbaleLoading: false,
    apiKeyDisable: "",
  },

  deleteMemberData: {
    isMemberDeleteLoading: false,
    memberDelete: [],
  },

  orgCheckData: {
    isOrgCheckDataLoading: false,
    orgDataCheck: [],
  },
};

export const memberInviteAction = createAsyncThunk(
  "memberInvitee/memberInviteAction",
  async ({ payload, handleInitaldata }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${MINSKY_URL.memberInviteApi}`,
        payload
      );
      if (response.status === 200) {
        handleInitaldata && handleInitaldata();
        toast.success("Invitation sent successfully");
        return response.data; // Return the response data
      } else {
        throw new Error("Failed to download bill: Invalid status code");
      }
    } catch (error) {
      // Log error to Sentry
      Sentry.captureException(error);
      return rejectWithValue(error.message); // Use rejectWithValue to pass error message
    }
  }
);

export const yourOrganizationAction = createAsyncThunk(
  "yourOrg/yourOrganizationAction",
  async ({ payload, handleCallback }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${MINSKY_URL.yourOrgApi}`,
        payload
      );
      if (response.status === 200) {
        handleCallback && handleCallback();
        toast.success("organization created successfully");
        return response.data; // Return the response data
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      // Log error to Sentry
      Sentry.captureException(error);
      return rejectWithValue(error.message); // Use rejectWithValue to pass error message
    }
  }
);

export const getOrgDataAction = createAsyncThunk(
  "getOrgData/getOrgDataAction",
  async () => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.orgDataApi}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    if (response.data.data?.error) {
      toast.error("Something went wrong");
    } else {
      return response.data;
    }
  }
);

export const getMemberDataAction = createAsyncThunk(
  "getMemberData/getMemberDataAction",
  async (payload) => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.memeberDataApi}?status=${payload.status}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const getMemberDeleteDataAction = createAsyncThunk(
  "deleteMemberData/getMemberDeleteDataAction",
  async ({ payload, handleInitaldata }) => {
    const response = await axiosInstance
      .delete(`${MINSKY_URL.deleteMemberApi}?email=${payload.email}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    if (response.status === 200) {
      handleInitaldata && handleInitaldata();
      toast.success("Member deleted successfully");
      return response.data;
    } else {
      toast.error(response.data.detail);
    }
  }
);

export const createApiKeyAction = createAsyncThunk(
  "createApiKey/createApiKeyAction",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${MINSKY_URL.crateApiKey}`,
        payload
      );
      console.log("res", response);
      if (response.status === 201) {
        console.log("ressss", response);
        toast.success(response.data.message);
        return response.data; // Return the response data
      } else {
        throw new Error("Failed to download bill: Invalid status code");
      }
    } catch (error) {
      // Log error to Sentry
      Sentry.captureException(error);
      return rejectWithValue(error.message); // Use rejectWithValue to pass error message
    }
  }
);

export const getApiKeyDataAction = createAsyncThunk(
  "getApiKeyData/getApiKeyDataAction",
  async () => {
    const response = await axiosInstance
      .get(`${MINSKY_URL.getApiKeyData}`)
      .catch((error) => {
        if (error.response.status === 500) {
          Sentry.captureException("Server Error");
        }
        return error.response;
      });
    if (response.status === 500) {
      Sentry.captureException("Server Error");
    }
    return response.data;
  }
);

export const deleteApiKeyAction = createAsyncThunk(
  "deleteApiKey/deleteApiKeyAction",
  async ({ payload, handleSuccessCB }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `${MINSKY_URL.apiKeyDelete}`,
        { data: payload }
      );

      if (response.status === 200) {
        handleSuccessCB && handleSuccessCB();
        toast.success(response.data.message);
        return response.data;
      } else {
        throw new Error("Failed to delete API key: Invalid status code");
      }
    } catch (error) {
      Sentry.captureException(error);

      return rejectWithValue(error.message);
    }
  }
);

export const disableApiKeyAction = createAsyncThunk(
  "disableApiKey/disableApiKeyAction",
  async ({ payload, handleSuccessCB }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${MINSKY_URL.apiKeyDelete}`,
        payload
      );
      console.log("res", response);
      if (response) {
        console.log("res", response);
        handleSuccessCB && handleSuccessCB();
        if (payload.is_active === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        return response.data; // Return the response data
      } else {
        throw new Error("Failed to download bill: Invalid status code");
      }
    } catch (error) {
      // Log error to Sentry
      Sentry.captureException(error);
      return rejectWithValue(error.message); // Use rejectWithValue to pass error message
    }
  }
);

export const orgCheckAction = createAsyncThunk(
  "createApiKey/orgCheckAction",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${MINSKY_URL.orgCheckDataApi}`);
      console.log("res", response);
      if (response) {
        console.log("res", response);
        return response.data; // Return the response data
      } else {
        throw new Error("Failed to download bill: Invalid status code");
      }
    } catch (error) {
      // Log error to Sentry
      Sentry.captureException(error);
      return rejectWithValue(error.message); // Use rejectWithValue to pass error message
    }
  }
);

export const settingsSlice = createSlice({
  name: "memberInvite",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(memberInviteAction.pending, (state, action) => {
        state.status = "pending";
        state.memberInvitee.isMemberInviteLoading = true;
      })
      .addCase(memberInviteAction.fulfilled, (state, action) => {
        state.memberInvitee.isMemberInviteLoading = false;
        state.memberInvitee.memberInviteData = action.payload;
      })
      .addCase(memberInviteAction.rejected, (state, action) => {
        state.status = "failed";
        state.memberInvitee.isMemberInviteLoading = false;
      })

      //--------------------------------------------------------------------------------------

      .addCase(yourOrganizationAction.pending, (state, action) => {
        state.status = "pending";
        state.yourOrg.isOrgDataLoading = true;
      })
      .addCase(yourOrganizationAction.fulfilled, (state, action) => {
        state.yourOrg.isOrgDataLoading = false;
        state.yourOrg.orgData = action.payload;
      })
      .addCase(yourOrganizationAction.rejected, (state, action) => {
        state.status = "failed";
        state.yourOrg.isOrgDataLoading = false;
      })

      //--------------------------------------------------------------------------------------

      .addCase(getOrgDataAction.pending, (state, action) => {
        state.status = "pending";
        state.getOrgData.isOrgDataLoading = true;
      })
      .addCase(getOrgDataAction.fulfilled, (state, action) => {
        state.getOrgData.isOrgDataLoading = false;
        state.getOrgData.getYourOrgData = action.payload;
      })
      .addCase(getOrgDataAction.rejected, (state, action) => {
        state.status = "failed";
        state.getOrgData.isOrgDataLoading = false;
      })

      //--------------------------------------------------------------------------------------

      .addCase(createApiKeyAction.pending, (state, action) => {
        state.status = "pending";
        state.createApiKey.isCreateApiKeyLoading = true;
      })
      .addCase(createApiKeyAction.fulfilled, (state, action) => {
        state.createApiKey.isCreateApiKeyLoading = false;
        state.createApiKey.createApiKeyData = action.payload;
      })
      .addCase(createApiKeyAction.rejected, (state, action) => {
        state.status = "failed";
        state.createApiKey.isCreateApiKeyLoading = false;
      })

      //--------------------------------------------------------------------------------------

      .addCase(getApiKeyDataAction.pending, (state, action) => {
        state.status = "pending";
        state.getApiKey.isGetApiKeyDataLoading = true;
      })
      .addCase(getApiKeyDataAction.fulfilled, (state, action) => {
        state.getApiKey.isGetApiKeyDataLoading = false;
        state.getApiKey.getApiKeyData = action.payload;
      })
      .addCase(getApiKeyDataAction.rejected, (state, action) => {
        state.status = "failed";
        state.getApiKey.isGetApiKeyDataLoading = false;
      })
      //--------------------------------------------------------------------------------------

      .addCase(deleteApiKeyAction.pending, (state, action) => {
        state.status = "pending";
        state.deleteApikey.isApiKeyDeletedLoading = true;
      })
      .addCase(deleteApiKeyAction.fulfilled, (state, action) => {
        state.deleteApikey.isApiKeyDeletedLoading = false;
        state.deleteApikey.apiKeyDelete = action.payload;
      })
      .addCase(deleteApiKeyAction.rejected, (state, action) => {
        state.status = "failed";
        state.deleteApikey.isApiKeyDeletedLoading = false;
      })

      //--------------------------------------------------------------------------------------

      .addCase(disableApiKeyAction.pending, (state, action) => {
        state.status = "pending";
        state.disableApiKey.isApiKeydisbaleLoading = true;
      })
      .addCase(disableApiKeyAction.fulfilled, (state, action) => {
        state.disableApiKey.isApiKeydisbaleLoading = false;
        state.disableApiKey.apiKeyDisable = action.payload;
      })
      .addCase(disableApiKeyAction.rejected, (state, action) => {
        state.status = "failed";
        state.disableApiKey.isApiKeydisbaleLoading = false;
      })

      //--------------------------------------------------------------------------------------

      .addCase(getMemberDataAction.pending, (state, action) => {
        state.status = "pending";
        state.getMemberData.isMemberDataLoading = true;
      })
      .addCase(getMemberDataAction.fulfilled, (state, action) => {
        state.getMemberData.isMemberDataLoading = false;
        state.getMemberData.memberData = action.payload;
      })
      .addCase(getMemberDataAction.rejected, (state, action) => {
        state.status = "failed";
        state.getMemberData.isMemberDataLoading = false;
      })

      //--------------------------------------------------------------------------------------

      .addCase(getMemberDeleteDataAction.pending, (state, action) => {
        state.status = "pending";
        state.deleteMemberData.isMemberDeleteLoading = true;
      })
      .addCase(getMemberDeleteDataAction.fulfilled, (state, action) => {
        state.deleteMemberData.isMemberDeleteLoading = false;
        state.deleteMemberData.memberDelete = action.payload;
      })
      .addCase(getMemberDeleteDataAction.rejected, (state, action) => {
        state.status = "failed";
        state.deleteMemberData.isMemberDeleteLoading = false;
      })

      //--------------------------------------------------------------------------------------

      .addCase(orgCheckAction.pending, (state, action) => {
        state.status = "pending";
        state.orgCheckData.isOrgCheckDataLoading = true;
      })
      .addCase(orgCheckAction.fulfilled, (state, action) => {
        state.orgCheckData.isOrgCheckDataLoading = false;
        state.orgCheckData.orgDataCheck = action.payload;
      })
      .addCase(orgCheckAction.rejected, (state, action) => {
        state.status = "failed";
        state.orgCheckData.isOrgCheckDataLoading = false;
      });
  },
});

// export const {} = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
