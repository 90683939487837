import Keycloak from "keycloak-js";
const ENV_UAT = true; // For proudction make it true, don't change in the local environment
let clientID = "";
if (ENV_UAT) {
  clientID = "aimlkeycloakuat";
} else {
  clientID = "aimlkeycloak";
}
const keycloakConfig = {
  realm: "neevrealm",
  url: "https://auth.ai.ml",
  clientId: clientID,
};
const keycloak = new Keycloak(keycloakConfig);
const initKeycloak = async () => {
  try {
    await keycloak.init({
      onLoad: "login-required",
    });
    keycloak.onAuthSuccess = (authenticated) => {
      console.log("Authentication successful:", authenticated);
    };
    console.log("Keycloak initialized successfully");
  } catch (error) {
    console.error("Failed to initialize adapter:", error);
  }
};
const KeycloakService = {
  initKeycloak,
  keycloak,
};
export default KeycloakService;
