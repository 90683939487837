import React, { useState, useContext, useEffect } from "react";
// import { Routers } from "../Navigation/Routers";
// import MinskyLogo from '../Assets/minskyLogo1.png'
// import MinskyShortM from '../Assets/minskyLogoM.png'
import { Outlet, useNavigate } from "react-router-dom";
import { LoginContext } from "../ContextApis/LoginContext";
// import { useLocation } from "react-router-dom";
import "./Layout.css";
import { Layout } from "antd";
import Cookies from "js-cookie";
import ExploreCard from "../Components/Dashboard/ExploreCard";
import GetMoreCard from "../Components/Dashboard/GetMoreCard";
import { dashboardJsonDataAction } from "../slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Reusable Components/Loader/Loader";

import KeycloakService from "../Utilities/KeycloakService";
const { Header, Content } = Layout;
const assetPath = "https://neurobridge-public.objectstore.e2enetworks.net";
const Logo = assetPath + "/aiml/logo.svg";
const Logout = assetPath + "/aiml/logout.svg";
const User_Small = assetPath + "/aiml/user_small.svg";
const Organisation_Outline = assetPath + "/aiml/organisation_outline.svg";
const Terms_Outline = assetPath + "/aiml/terms_outline.svg";
const User_plus_Outline = assetPath + "/aiml/user_outline_plus.svg";
const Card_Outline = assetPath + "/aiml/card_outline.svg";
const Key_Outline = assetPath + "/aiml/key_outline.svg";
const File_Outline = assetPath + "/aiml/file_outline.svg";
const Privacy_Outline = assetPath + "/aiml/privacy_outline.svg";
export const LayoutDashboard = () => {
  // const location = useLocation();
  const dispatch = useDispatch();
  // const [selectedKey, setSelectedKey] = useState("profile");
  const [isActive, setIsActive] = useState(false);
  const [greeting, setGreeting] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [userDetail, setUserDetail] = useState(null);

  const dashboardData = useSelector(
    (state) => state.dashboard.dashbaoardJsonData.dashboardData
  );

  const isDashboardJsonDataLoading = useSelector(
    (state) => state.dashboard.dashbaoardJsonData.isDashboardJsonDataLoading
  );

  const { keycloak } = KeycloakService;

  const labeltext = dashboardData?.labeltext;

  // const menuData = useSelector((state) => state.model.textToTextModelTypes);
  const {
    setUserId,
    setIsAuthenticated,
    setFirstName,
    setLastName,
    setProfileEmail,
    setProfilePhone,
    setAuthData,
    handleLogoutUser,
    tokenParsedData,
  } = useContext(LoginContext);
  const navigate = useNavigate();
  // const {
  //   token: { colorBgContainer, borderRadiusLG },
  // } = theme.useToken();
  // const handleOpenChange = (newOpen) => {
  //   setOpen(newOpen);
  // };

  // useEffect(() => {
  //   dispatch(textToTextModelTypes());
  // }, [dispatch]);

  useEffect(() => {
    const retrive = async () => {
      try {
        const info = await keycloak.loadUserProfile();
        setUserDetail(info);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    retrive();
  }, [keycloak]);

  const handleDashboardNavigation = () => {
    // const menuItem = menuData?.find((item) => item?.model_type === "chat");
    // if (menuItem) {
    //   const { model_type, model_id } = menuItem;
    //   console.log(model_type, "selected model type");
    // setselectedMenu(model_type);
    navigate(`/playground/chat`);
    // }
  };
  const handleSettingNavigation = () => {
    navigate("/setting/yourOrganisation");
  };
  const navigateAPIDoc = (tab) => {
    if (tab === "docs") {
      window.open("http://api.ai.ml/docs/swagger/", "_blank");
    } else {
      window.open("https://community.ai.ml/", "_blank");
    }
  };
  const handleLogoutDrop = () => {
    setIsActive(!isActive);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    // Cookies.remove("keyCloakToken", keyCloakToken);
    setIsAuthenticated(false);
    setAuthData(false);
    setFirstName(false);
    setLastName(false);
    setProfileEmail(false);
    setProfilePhone(false);
    setUserId(false);
    handleLogoutUser();
    const cookies = Cookies.get();

    // Iterate over all cookies and remove each one
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
    // window.location.reload();
    navigate("/");
  };

  // const handleProfileBalanceClick = () => {
  //   navigate("/dashboard/billing");
  // };
  // const handleMenuClick = ({ key }) => {
  //   setSelectedKey(key);
  //   navigate(key);
  // };

  const handleModelCardClick = (cardName) => {
    navigate(`/playground/chat`, { state: { cardName } });
  };
  // const handlePrivacy = () => {
  //   navigate("/privacy");
  // };
  // const handleTos = () => {
  //   navigate("/tos");
  // };
  const handleCloseDropdown = () => {
    setIsActive(false);
  };
  const handlePrivacy = () => {
    navigate("/privacy");
  };
  const handleToS = () => {
    navigate("/tos");
  };
  const handleMenuClickDropdown = (key) => {
    if (key === "privacy" || key === "tos") {
      navigate(`/${key}`);
    } else {
      navigate(`/setting/${key}`);
    }
  };
  // useEffect(() => {
  //   const pathSegments = location.pathname.split("/");
  //   const key = pathSegments[pathSegments.length - 1];
  //   setSelectedKey(key || "profile");
  // }, [location]);

  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDay = days[currentDate.getDay()];

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentMonth = months[currentDate.getMonth()];

    const currentDateNum = currentDate.getDate();

    setDay(currentDay);
    setMonth(currentMonth);
    setDate(currentDateNum);

    if (currentHour < 12) {
      setGreeting("Good Morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, []);

  useEffect(() => {
    dispatch(dashboardJsonDataAction());
  }, [dispatch]);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Layout style={{ marginLeft: 200 }}>
          <div className="close">
            <Header className="header-wrapper mobile-header-active">
              <div className="header-logo">
                <img
                  src={Logo}
                  className="logo"
                  alt="logo"
                  onClick={() => navigate("/dashboard")}
                />
              </div>

              <div className="nav-links">
                <ul>
                  <li>
                    <div
                      className={`footer_sider_bar ${"active"}`}
                      onClick={() => navigate("/dashboard")}
                    >
                      Dashboard
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div
                      onClick={handleDashboardNavigation}
                      className={`footer_sider_bar`}
                    >
                      Playground
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div
                      className={`footer_sider_bar `}
                      onClick={handleSettingNavigation}
                    >
                      Settings
                    </div>
                  </li>
                </ul>
              </div>
              <div className="user-settings-main">
                <div
                  onClick={() => navigateAPIDoc("community")}
                  className="docs-cum"
                >
                  Community
                </div>
                <div
                  onClick={() => navigateAPIDoc("docs")}
                  className="docs-cum"
                >
                  Docs
                </div>
                <div className="logout-wrap">
                  <img
                    src={Logout}
                    onClick={handleLogoutDrop}
                    className="logout-icon"
                    alt="logoutIcon"
                  />

                  {isActive ? (
                    <>
                      <div
                        className="drop-backDrop"
                        onClick={handleCloseDropdown}
                      >
                        {/* close */}
                      </div>
                      <div className="drop-list">
                        <div className="drop-header">
                          <img src={User_Small} alt="icon" />
                          <div>
                            <h4 className="drop-title">
                              {userDetail?.username}
                            </h4>
                            <p className="drop-text">{userDetail?.email}</p>
                          </div>
                        </div>
                        <div className="drop-body ">
                          <p
                            className="drop-item "
                            onClick={() =>
                              handleMenuClickDropdown("yourOrganisation")
                            }
                          >
                            <img src={Organisation_Outline} alt="icon" />
                            Your Organisation
                          </p>
                          <p
                            className="drop-item"
                            onClick={() =>
                              handleMenuClickDropdown("membersInvites")
                            }
                          >
                            <img src={User_plus_Outline} alt="icon" />
                            Members & Invites
                          </p>
                          <p
                            className="drop-item"
                            onClick={() =>
                              handleMenuClickDropdown("plansBilling")
                            }
                          >
                            <img src={Card_Outline} alt="icon" />
                            Plan & Pricing
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("apiKeys")}
                          >
                            <img src={Key_Outline} alt="icon" />
                            API Keys
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("logs")}
                          >
                            <img src={File_Outline} alt="icon" />
                            Logs
                          </p>
                          {/* <p className="drop-item">
                          <img src={Data_Outline} alt="icon" />
                          Usage
                        </p> */}
                        </div>
                        <div className="drop-footer">
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("privacy")}
                          >
                            <img src={Privacy_Outline} alt="icon" />
                            Privacy Policy
                          </p>
                          <p
                            className="drop-item"
                            onClick={() => handleMenuClickDropdown("tos")}
                          >
                            <img src={Terms_Outline} alt="icon" />
                            Terms of Service
                          </p>
                          <p
                            className="drop-item logout"
                            onClick={handleLogout}
                          >
                            Logout
                          </p>
                        </div>
                        {/* <li
                        className="drop-item"
                        onClick={handleProfileBalanceClick}
                      >
                        Wallet
                      </li> */}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Header>
          </div>
          {/* <div className="sub-header-wrapper">
            <div className="menu-items-main">
              <Menu
                mode="inline"
                selectedKeys={[selectedKey]}
                onClick={handleMenuClick}
                items={[
                  {
                    key: "profile",
                    icon: <UserOutlined />,
                    label: "Profile",
                  },
                  {
                    key: "apikeys",
                    icon: <KeyOutlined />,
                    label: "API Keys",
                  },
                  {
                    key: "analytics",
                    icon: <DashboardOutlined />,
                    label: "Analytics",
                  },
                  {
                    key: "billing",
                    icon: <CreditCardOutlined />,
                    label: "Payment",
                  },
                ]}
              />
              <div className="btn-main">
                <button type="button" className="side-btn outlined">
                  <img src={Question} alt="question" />
                  Get Help
                </button>
                <button type="button" className="side-btn filled">
                  <img src={Code} alt="code" />
                  View Code
                </button>
                <div className="dropDown">
                  <button type="button" className="drop-btn">
                    <img src={Dots} alt="code" />
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          <Content>
            <div className="dashboard-wrapper">
              {!isDashboardJsonDataLoading ? (
                <div className="dash-container">
                  <div className="dash-header">
                    <h3 className="page-title">
                      {day}, {month} {date}
                    </h3>
                    <h2 className="page-subtitle">
                      {greeting},{" "}
                      <span>{`${tokenParsedData ? tokenParsedData : ""}`}</span>
                    </h2>
                    {/* <Button onClick={handlePrivacy}>Privacy</Button>
                    <Button onClick={handleTos}>Tos</Button> */}
                  </div>
                  <div className="explore-module">
                    <h3 className="page-title">
                      <span>{dashboardData?.exploreModuleText}</span>
                    </h3>
                    <div className="row explore-card-wrapper">
                      {dashboardData?.exploreData?.map((item) => (
                        <ExploreCard
                          id={item?.id}
                          title={item?.title}
                          isTitleTag={item?.titleTag}
                          text={item?.text}
                          isFooterShow={item?.isFooterShow}
                          features={item?.features}
                          assetPath={assetPath}
                          labelText={labeltext}
                          handleModelCardClick={handleModelCardClick}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="getMore-aiml">
                    <h3 className="page-title">
                      <span> {dashboardData?.getMoreOfAiMltext}</span>
                    </h3>
                    <div className="row getMore-card-wrapper">
                      {dashboardData?.getMoreData?.map((item) => (
                        <GetMoreCard
                          key={item.id}
                          title={item.title}
                          icon={item.icon}
                          text={item.text}
                          btnText={item.btnText}
                          route={item.route}
                          openNewTab={item.openNewTab}
                          assetPath={assetPath}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="dash-footer">
                    <h2 className="footer-title">
                      {dashboardData?.footerHeading} <br />{" "}
                      {dashboardData?.footerSubHeading}
                    </h2>
                    <div className="footer-btn-box">
                      <p className="footer-copy-text">
                        {dashboardData?.copyRightFooterText}
                      </p>
                      <div className="d-flex align-items-center">
                        <button
                          type="button"
                          className="footer-btn"
                          onClick={handleToS}
                        >
                          Terms of Services
                        </button>
                        <button
                          type="button"
                          className="footer-btn"
                          onClick={handlePrivacy}
                        >
                          Privacy Policy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 180,
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </Content>
        </Layout>
      </Layout>
      <Outlet />
    </>
  );
};
